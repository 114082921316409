import { useState, useEffect, useRef } from "react";
import { AccountCircleOutlined, KeyboardArrowDownOutlined } from "@mui/icons-material"
import { IconButton, Toolbar, Typography } from "@mui/material"
import { styled } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import { getTopNavColorConfig } from '../../../state/slices/colorConfigSlice';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { msalInstance } from "../../Site/SiteHeader";
import { useNavigate } from "react-router-dom";
import { AppDashboards, AppRoles } from "../../../utils/enum";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { useDispatch } from "react-redux";
import { getProfileIcon } from "../../../state/slices/userDetailsSlice";
import InfoIcon from '@mui/icons-material/Info';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import SimpleBadge from "../NotificationsIcon/Notificationsicon";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CancelIcon from '@mui/icons-material/Cancel';
import { useCallback } from "react";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


export const TopNavBar = (props) => {
  const manageIconRef = useRef(null);
  const { fullName, hideMenuButton } = props;
  const [showMenu, setShowMenu] = useState(false);
  const topNavColorConfig = useSelector(getTopNavColorConfig);

  const profileIcon = useSelector(getProfileIcon);

  const navigate = useNavigate();

  const emailId = secureLocalStorage.getItem("userEmail");
  // document.addEventListener("mousedown", handleClickOutside);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const handleBodyClick = (event) => {
      if (manageIconRef.current && manageIconRef.current.contains(event.target)) {
      } else {
        setShowMenu(false);
      }
    };

    document.body.addEventListener('click', handleBodyClick);

    return () => {
      document.body.removeEventListener('click', handleBodyClick);
    };
  }, []);

  const onLogoutClick = () => {
    setShowPopup(true);
    setShowMenu(false);
  }
  const handleConfirmRemove = () => {
    secureLocalStorage.setItem("fullName", "");
    secureLocalStorage.clear();
    msalInstance.logoutRedirect({ postLogoutRedirectUri: "/?login_page=true" });
    setShowPopup(false);
  };

  const handleCancelRemove = () => {
    // Close the popup without removing the item
    setShowPopup(false);
    // setShowMenu(!showMenu);
    setShowMenu(false)
  };

  const handleHelpAndSupport = () => {
    console.log('Help & Support');
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split('/').slice(0, 3).join('/');
    window.open(baseUrl + '/helpAndsupport', '_blank');
  };

  const handleManageAccounts = () => {
    // Perform manage accounts functionality
    console.log('Manage Accounts');
    setShowMenu(false);
  };

  const manageaccountclick = () => {
    const userRole = secureLocalStorage.getItem("userrole");

    if (userRole !== AppDashboards.INV_DASHBOARD) {
      /* navigate("/UserDetails"); */
      navigate("/firm-user-profile");
    } else {
      navigate("/user-profile");
    }
    setShowMenu(false);
  };
  const handleMenuToggle = () => {
    setShowMenu(!showMenu);
  };


  return (
    <AppBar className="app-top-bar" sx={{ backgroundColor: topNavColorConfig.backgroundColor}}>
      <Toolbar className="toolbarspace">
          <span className="user-logo">
              <img src={topNavColorConfig.logoUrl} alt="Alt360 Logo"/>
          </span>
            {!hideMenuButton && (
              <div className="app-topbar-right" >
                {secureLocalStorage.getItem("userrole") !== AppDashboards.SUPERADMIN_DASHBOARD && <span><SimpleBadge /></span>}
                <div className='user-account' ref={manageIconRef}>
                  <span onClick={handleMenuToggle}>
                    {/* BUG 1894 */}
                    {
                      profileIcon && profileIcon !== "" ? (
                        <img src={profileIcon} alt="" className="menu-profile-image" />
                      ) : (<AccountCircleOutlined className="circleIcon" sx={{ color: topNavColorConfig.iconColor}} />)

                    }
                    {/* <AccountCircleOutlined sx={{color: topNavColorConfig.iconColor, marginLeft:'54px', width:'25px',height: '25px' }} /> */}
                    <KeyboardArrowDownOutlined className="down-arrow" sx={{ color: topNavColorConfig.iconColor}} />
                  </span>

                </div>

              </div>

            )}

            {/* <img className="userMenuIcon" alt="" src="/user-menu.svg" onClick={handleMenuToggle} /> */}
            {showMenu && (
              <div className="user-accountbox">
                <h6 className="user-account-menu" onClick={handleManageAccounts}>{fullName}</h6>
                <p>UserName : {secureLocalStorage.getItem("userName")}</p>
                <p>{emailId}</p>
                {
                  secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD && 
                  <p className="investorType">{secureLocalStorage.getItem("investorType")}</p>
                }
                <hr className="line" />
                <div className="user-account-menuitem" onClick={manageaccountclick} >
                  <ManageAccountsIcon  className="manageAccountsIcon"/>
                  MANAGE ACCOUNT</div> <div ></div>
                <div className="user-account-menuitem" onClick={handleHelpAndSupport}>
                <HelpOutlineIcon  className="manageAccountsIcon"/>
                  HELP & SUPPORT   </div>    <div ></div>
                <div className="user-account-menuitem" onClick={onLogoutClick}>
                  <ExitToAppIcon className="LogoutIcon"/>
                  LOGOUT
                </div>
              </div>

            )}
      
   
      </Toolbar>
      {showPopup ? (
        <Dialog open={showPopup} className="logout-pop">
          <DialogTitle>
            <h6><InfoIcon className='info-i' />Confirmation</h6>
          </DialogTitle>
          <DialogContent>
            Are you sure you want to logout?
          </DialogContent>
          <DialogActions>
            <div className="log-confirm-btns">

              <Button className='btn-primary'
                component="label"
                variant="outlined"
                // style={{ width: '100px', backgroundColor: 'white', color: 'gray' }}
                onClick={handleCancelRemove}
              >
                <CancelIcon />
                Cancel
              </Button>

              <Button className="btn-primary"
                component="label"
                variant="contained"
                // style={{ width: '100px' }}
                onClick={handleConfirmRemove}
              >
                <LogoutIcon />
                LOGOUT
              </Button>

            </div>
          </DialogActions>
        </Dialog>
      ) : null}
    </AppBar>
  )
}