import { IMaskInput } from 'react-imask';
import React from 'react';
import { NumericFormat } from 'react-number-format';

export const usPhoneNumberFormat = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask="(#00) 000-0000"
      definitions={{
        '#': /[1-9]/,
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});

const AlphabeticInput = React.forwardRef((props, ref) => {
  const handleChange = (event) => {
    const { value } = event.target;
    // Ensure that the value contains only alphabetic characters
    const newValue = value.replace(/[^A-Za-z\s]/g, ''); // Replace any character that is not a letter with an empty string
    // Invoke the onChange callback with the new value
    props.onChange({ target: { name: props.name, value: newValue } });
  };

  return (
    <input
      {...props}
      ref={ref}
      onChange={handleChange}
    />
  );
});

export default AlphabeticInput;

export const NumericInput = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, value, ...other } = props;

  // Ensure value is always a string or undefined for the NumericFormat component
  const formattedValue = value !== undefined ? String(value) : '';

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      value={formattedValue}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value, // NumericFormat ensures this is a string or empty string
          },
        });
      }}
    />
  );
});


export const usCurrencyFormat = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, value, ...other } = props;

  // Ensure value is always a string or undefined for the NumericFormat component
  const formattedValue = value !== undefined ? String(value) : '';

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      value={formattedValue}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value, // NumericFormat ensures this is a string or empty string
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    /* prefix="$" */
    />
  );
});

export const zipcode = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <IMaskInput
      {...other}
      mask={[
        {
          mask: '00000',
          maxLength: 5
        },
        {
          mask: '00000-0000'
        }
      ]}
      definitions={{
        '#': /[0-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});


export const Percentage = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, value, ...other } = props;

  // Ensure value is always a string or an empty string for the NumericFormat component
  const formattedValue = value !== undefined ? String(value) : '';

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      value={formattedValue}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value, // NumericFormat ensures this is a string or an empty string
          },
        });
      }}
      decimalScale={2}
      allowLeadingZeros={false}
      placeholder=""
    />
  );
});


export const AlphanumericInput = React.forwardRef(function AlphanumericInput(props, ref) {
  const { onChange, ...other } = props;

  // Regular expression to accept only alphanumeric characters
  const alphanumericMask = /^[a-zA-Z0-9\s]*$/;

  return (
    <IMaskInput
      {...other}
      mask={alphanumericMask}
      lazy={false} // disable lazy masking
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite={false}
    />
  );
});

export const AllCharactersInput = React.forwardRef(function AllCharactersInput(props, ref) {
  const { onChange, ...other } = props;

  const allCharacterMask = /.*/;

  return (
    <IMaskInput
      {...other}
      mask={allCharacterMask}
      lazy={false} // disable lazy masking
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite={false}
    />
  );
});


export const AppTextInputCurrencyFormat = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
    /* prefix="$" */
    />
  );
});

export const AppTextPercentageFormat = React.forwardRef(function TextMaskCustom(props, ref) {
  const { onChange, ...other } = props;
  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      allowNegative={false}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      /* suffix="%" */
      decimalScale={2}
      allowLeadingZeros={false}
      placeholder=""
    />
  );
});

export const SSNFormat = React.forwardRef(function SSNFormat(props, ref) {
  const { onChange, ...other } = props;

  return (
    <IMaskInput
      {...other}
      mask="AAA-AA-0000"
      definitions={{
        'A': /[A-Za-z0-9]/,
        '0': /[0-9]/
      }}
      inputRef={ref}
      onAccept={(value) => onChange({ target: { name: props.name, value } })}
      overwrite
    />
  );
});
