import React, { useEffect, useState } from 'react'
import { validationsConstants } from '../../../utils/AppConstants';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { articleTypes, articleTypesEnum, readingsInitialData, sectionTypesEnum, visibilityTypes } from '../apiDataTemplate';
import { Checkbox, FormControl, FormHelperText, InputLabel, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import MultiSelectField from '../../common/input-fields/MultiSelectField';
import ALTTextField from '../../common/input-fields/ALTTextField';
import FileUploadBtn from '../../common/FileUploadButton/FileUploadBtn';
import SelectField from '../../common/input-fields/SelectField';
import RadioGroupField from '../../common/input-fields/RadioGroupField';
import { getKeyValuePairs } from '../../portfolio-companies/services/services';
import { createNewArticle, getAutoCurations, getFundNames, getFundTags, getNewsArticleByID, updateNewsArticle } from '../services/services';
import { getAllFunds } from '../../FundManagerDashboard/Services/FundNewsService';
import { toast,ToastContainer } from 'react-toastify';
import { uploadFileTypes } from '../../common/FileUploadButton/uploadComponentenum';
import { convertArrayToString, convertStringToArrayWithEmptyArray } from '../../common/Functions/ConvertStringtoArray';
import ValidationPopup from '../popups/ValidationPopup';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import ActionButton from '../../common/ActionButton/ActionButton';
// import CloudUploadIcon from '@mui/icons-material/CloudUpload';
const UploadReadings = (props) => {

  //#region props
  const { selectedReadingID,setSelectedArticle,getSuggestedArticles } = props;

  //#region variables
  const [loading, setLoading] = useState(false);
  const [autoCurationDetails, setAutoCurationsDetails] = useState(null);
  const [sectionsDropdown, setSectionDetails] = useState(null);
  const [subscriptionStatusDropdown, setSubscriptionDetails] = useState(null);
  const [fundManagementDropdown, setFundManagementDetails] = useState(null);
  const [allTagsDetails, setAllTagsDetails] = useState();
  const [fundNames, setFundNames] = useState();
  const [fundTypes, setFundTypes] = useState();
  const [clearFile,setClearFile] = useState(false);
  const [validationPopup,setValidationPopup] = useState(false);
  const [actualFunds, setActualFunds] = useState();
  //#region change events
  const handleFileUpload = (name, file) => {
    if (file) { 
        const formData = new FormData();
        formData.append(name, file, file.name);
        handleChange(name, file);
    }
    else{
        handleChange(name, '');
    }
}

const handleChange = (name, value) => {
  if(name === "type"){
    if(value === articleTypesEnum.ADD_LINK){
      setClearFile(true);
    }else if(value === articleTypesEnum.UPLOAD_FILE){
      suggestedReadingValues.setFieldValue("articleLinkName", "");
    }
  }
  if(name == "fundTypes"){
    let filteredFunds = actualFunds.map((fund) =>  {
      if(value.includes(fund.fundTypeID)){
         return fund;
      }
     });
    setFundNames(filteredFunds.filter(a => a != undefined));
  }
  suggestedReadingValues.setFieldValue(name, value);
}

  //#region click events
  const openValidationPopup = () => {
    if(suggestedReadingValues.values.type === articleTypesEnum.UPLOAD_FILE && suggestedReadingValues.values.articleUploadFileName === ""){
      toast.warning("please upload a file",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      return
    }
    setValidationPopup(true);
  }

  const closeValidationpopup = () => {
    setValidationPopup(false)
  }

  const onCancelClick = () => {
    setClearFile(true);
      suggestedReadingValues.resetForm();
    setSelectedArticle(0);
   }


  //#region functions
  const fundManagementField = () => {
    if (!sectionsDropdown || !suggestedReadingValues?.values?.sections.length > 0) {
      return true;
    }
    const selectedSectionLabels = sectionsDropdown
      .filter(item => suggestedReadingValues?.values?.sections?.includes(item?.listItemID))
      .map(item => item?.listItemValue);
    return !selectedSectionLabels.includes(sectionTypesEnum.FUND_MANAGER_DASHBOARD);
  };

  const subscriptionStatusField = () => {
    if (!sectionsDropdown || !suggestedReadingValues?.values?.sections.length > 0) {
      return true;
    }
    const selectedSectionLabels = sectionsDropdown
      .filter(item => suggestedReadingValues?.values?.sections?.includes(item?.listItemID))
      .map(item => item?.listItemValue);
    return !selectedSectionLabels.includes(sectionTypesEnum.INVESTOR_DASHOBARD);
  };

  //#region api get calls
  const getSubscriptionStatus = async () => {
    const data = await getKeyValuePairs("SubscriptionStatus");
    if (data.responseCode === 200) {
      setSubscriptionDetails(data.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getFundManagement = async () => {
    const data = await getKeyValuePairs("FundManagement");
    if (data.responseCode === 200) {
      setFundManagementDetails(data.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getSections = async () => {
    const data = await getKeyValuePairs("Sections");
    if (data.responseCode === 200) {
      // debugger;
      setSectionDetails(data.responseData);
      // suggestedReadingValues.setFieldValue("sections", data.responseData.map((tag) =>  tag.listItemID ))
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getAutoCuraionDetails = async () => {
    const data = await getAutoCurations();
    if (data.responseCode === 200) {
      const filerCurationDetails = data.responseData?.filter((item)=> !(item.autoCurationName === "All"))
      setAutoCurationsDetails(filerCurationDetails);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getTagsDetails = async () => {
    const data = await getFundTags();
    if (data.responseCode === 200) {
      setAllTagsDetails(data.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getFundTypes = async () =>{
    const data = await getKeyValuePairs('FundType');
    if (data.responseCode === 200) {
      const NamesAndIds = data.responseData
        ?.filter((item)=>item.listItemID !== -1)
        .map(item => ({
        label: item.listItemValue,
        value: item.listItemID,
      }));
      setFundTypes(NamesAndIds);
      
      // suggestedReadingValues.setFieldValue("fundTypes", NamesAndIds.map((tag) =>  tag.value ));
    }
    else {
      //console.log('unable to fetch data)
    }
  }
  const getDashboardFundNames = async () => {
    const data = await getAllFunds();
    if (data.responseCode === 200) {
      // debugger;
      const NamesAndIds = data.responseData.map(item => ({
        label: `${item.fundName} (${item.fundManager})`,
        value: item.fundID,
        fundTypeID : item.fundTypeID
      }));
      setFundNames(NamesAndIds);
      setActualFunds(NamesAndIds);
      // suggestedReadingValues.setFieldValue("regardingFunds", NamesAndIds.map((tag) =>  tag.value ));
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getArticleByID = async (articleID) => {
    const data = await getNewsArticleByID(articleID);
    if (data.responseCode === 200) {
      const articleDetails = data.responseData;
      //converting staring to array
      
      articleDetails.regardingFunds = articleDetails.regardingFunds ? convertStringToArrayWithEmptyArray(articleDetails.regardingFunds) : [];
      articleDetails.addTags = articleDetails.addTags ? convertStringToArrayWithEmptyArray(articleDetails.addTags) : [];
      articleDetails.autoCuration = convertStringToArrayWithEmptyArray(articleDetails.autoCuration);
      articleDetails.fundTypes = articleDetails.fundTypes ? convertStringToArrayWithEmptyArray(articleDetails.fundTypes) : [];
      articleDetails.sections = convertStringToArrayWithEmptyArray(articleDetails.sections);
      articleDetails.fundManagement = convertStringToArrayWithEmptyArray(articleDetails.fundManagement);
      articleDetails.type = articleDetails.uploadFile === true ? articleTypesEnum.UPLOAD_FILE : articleTypesEnum.ADD_LINK;
      articleDetails.hidden =  articleDetails.hidden === true ? "true" : "false";
      suggestedReadingValues.setValues(articleDetails);
    }
    else {
      //console.log('unable to fetch data)
      onCancelClick();
    }
  }

  //#region api post calls
  const createAndUpdateArticle = async () => {
    setLoading(true);
    closeValidationpopup();
    const requestBody = {...suggestedReadingValues.values};
    //updating add link and upload file values
    requestBody.uploadFile = requestBody.type === articleTypesEnum.UPLOAD_FILE;
    requestBody.addLink = requestBody.type === articleTypesEnum.ADD_LINK;
    const formDataBody = new FormData();
    
    if (requestBody.uploadFile === true && requestBody.articleUploadFileName instanceof Blob) {
        formDataBody.append('file', requestBody.articleUploadFileName);
        requestBody.articleUploadFileName = "";
    }
    else if(requestBody.uploadFile === true && requestBody.articleUploadFileName !== ""){
       //
    }
    else if(requestBody.uploadFile === true){
      toast.warning("please upload a file",
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      setLoading(false);
      return
    }
    
    //converting array to string 
    requestBody.autoCuration = convertArrayToString(requestBody.autoCuration);
    requestBody.regardingFunds = convertArrayToString(requestBody.regardingFunds);
    requestBody.fundTypes = convertArrayToString(requestBody.fundTypes);
    requestBody.addTags = convertArrayToString(requestBody.addTags);
    requestBody.sections = convertArrayToString(requestBody.sections);
    requestBody.fundManagement = convertArrayToString(requestBody.fundManagement);

    formDataBody.append("suggestedArticle", JSON.stringify(requestBody));

    let data = '';
    if(requestBody.suggestedArticleID === 0){
      data = await createNewArticle(formDataBody);
    }
    else{
      data = await updateNewsArticle(formDataBody);
    }
    if (data.responseCode === 200) {
      getSuggestedArticles();
      setLoading(false);
      onCancelClick();
      toast.success(`Article ${requestBody.suggestedArticleID === 0 ? "Created" : "Updated"} Successfully`,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
    else {
      setLoading(false);
      onCancelClick();
      toast.error(`unable to ${requestBody.suggestedArticleID === 0 ? "Create" : "Update"} article please try again later`,
        { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
    }
  }

  //#region useeffect
  useEffect(() => {
    getSubscriptionStatus();
    getFundManagement();
    getAutoCuraionDetails();
    getSections();
    getTagsDetails();
    getDashboardFundNames();
    getFundTypes();
  }, [])

  useEffect(()=>{
    if(selectedReadingID && selectedReadingID !== 0){
      getArticleByID(selectedReadingID);
    }
    else{
      onCancelClick();
    }
  },[selectedReadingID])

  //#region formik validations
  const suggestedReadingsSchema = yup.object().shape({
    autoCuration: yup.array()
      .required(validationsConstants.REQUIRED)
      .min(1, validationsConstants.REQUIRED),
    type: yup
      .string()
      .required(`Please select either "Upload File" or "Add Link"`),
    articleTitle: yup
      .string()
      .required(validationsConstants.REQUIRED),
    articleSubtitle: yup
      .string()
      .required(validationsConstants.REQUIRED),
    articleAuthor: yup
      .string()
      .required(validationsConstants.REQUIRED),
    articleLinkName: yup.string().when([], (phoneNo, schema) => {
        if (suggestedReadingValues.values.type === articleTypesEnum.ADD_LINK) {
            return schema.required(validationsConstants.REQUIRED);
        }
        return schema;
    }),    
    fundTypes: yup.array()
      .required(validationsConstants.REQUIRED)
      .min(1, validationsConstants.REQUIRED),
    regardingFunds: yup.array()
      .required(validationsConstants.REQUIRED)
      .min(1, validationsConstants.REQUIRED),
    addTags: yup.array()
      .required(validationsConstants.REQUIRED)
      .min(1, validationsConstants.REQUIRED),
    hidden: yup
      .string()
      .required(validationsConstants.REQUIRED),
    sections: yup.array()
      .min(1, validationsConstants.REQUIRED)
      .required(validationsConstants.REQUIRED),
    fundManagement: yup
      .array()
      .when("sections", (sections, schema) => {
        if (!fundManagementField()) {
          return schema
            .min(1, validationsConstants.REQUIRED)
            .required(validationsConstants.REQUIRED)
            /* .test(
              'no-nan',
              validationsConstants.REQUIRED,
              value => Array.isArray(value) && value.every(item => !isNaN(item))
            ); */
        }
        return schema;
      }),    
    investorSubscriptionStatus: yup
      .number()
      .when("sections", (sections, schema) => {
        if (!(subscriptionStatusField()))
          return schema.min(1, validationsConstants.REQUIRED)
            .required(validationsConstants.REQUIRED)
        return schema
      }),
  });

  const suggestedReadingValues = useFormik({
    initialValues: readingsInitialData,
    validationSchema: suggestedReadingsSchema,
    onSubmit: (values) => {
      openValidationPopup()
    },
  });

  //#region return
  return (
    <div className='white-card uploadreadings'>
      <h6>Upload Readings</h6>
      <form onSubmit={suggestedReadingValues.handleSubmit} novalidate="novalidate" >
        <div className='display-row-items-3'>
          <div>
            <MultiSelectField
              name="autoCuration"
              label="Auto Curation"
              allOption={true}
              value={suggestedReadingValues?.values?.autoCuration || []}
              onChange={(name, value) => handleChange(name, value)}
              options={autoCurationDetails ? autoCurationDetails?.map(option => ({ label: option?.autoCurationName, value: option?.autoCurationID })) : []}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.autoCuration && Boolean(suggestedReadingValues.errors.autoCuration)}
              errorMessage={suggestedReadingValues.touched.autoCuration && suggestedReadingValues.errors.autoCuration} />
          </div>
          <div>
            <RadioGroupField
              name="type"
              value={suggestedReadingValues?.values?.type || []}
              options={articleTypes}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.type && Boolean(suggestedReadingValues.errors.type)}
              errorMessage={suggestedReadingValues.touched.type && suggestedReadingValues.errors.type}
            />
          </div>
        </div>
        <div className='display-row-items-3'>
          <div>
            <ALTTextField
              name="articleTitle"
              label="Article Title"
              value={suggestedReadingValues?.values?.articleTitle || []}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.articleTitle && Boolean(suggestedReadingValues.errors.articleTitle)}
              errorMessage={suggestedReadingValues.touched.articleTitle && suggestedReadingValues.errors.articleTitle} />
          </div>
          <div>
            <ALTTextField
              name="articleSubtitle"
              label="Article Subtitle"
              value={suggestedReadingValues?.values?.articleSubtitle || []}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.articleSubtitle && Boolean(suggestedReadingValues.errors.articleSubtitle)}
              errorMessage={suggestedReadingValues.touched.articleSubtitle && suggestedReadingValues.errors.articleSubtitle} />
          </div>
          <div>
            <ALTTextField
              name="articleAuthor"
              label="Article Author"
              value={suggestedReadingValues?.values?.articleAuthor || []}
              onChange={(name, value) => handleChange(name, value)}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.articleAuthor && Boolean(suggestedReadingValues.errors.articleAuthor)}
              errorMessage={suggestedReadingValues.touched.articleAuthor && suggestedReadingValues.errors.articleAuthor} />
          </div>
          <div>
            <ALTTextField
              name="articleLinkName"
              label="Article URL"
              value={suggestedReadingValues?.values?.articleLinkName || []}
              onChange={(name, value) => handleChange(name, value)}
              required={suggestedReadingValues?.values?.type === articleTypesEnum.ADD_LINK}
              disabled={!(suggestedReadingValues?.values?.type === articleTypesEnum.ADD_LINK)}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.articleLinkName && Boolean(suggestedReadingValues.errors.articleLinkName)}
              errorMessage={suggestedReadingValues.touched.articleLinkName && suggestedReadingValues.errors.articleLinkName} />
          </div>
          <div>
            <MultiSelectField
              name="addTags"
              label="Tags"
              allOption={true}
              value={suggestedReadingValues?.values?.addTags || []}
              onChange={(name, value) => handleChange(name, value)}
              options={allTagsDetails ? allTagsDetails?.map(option => ({ label: option?.fundSubTypeName, value: option?.fundSubTypeID })) : []}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.addTags && Boolean(suggestedReadingValues.errors.addTags)}
              errorMessage={suggestedReadingValues.touched.addTags && suggestedReadingValues.errors.addTags} />
          </div>
          <div>
            <SelectField
              name="hidden"
              label="Visibility"
              value={suggestedReadingValues?.values?.hidden || []}
              onChange={(name, value) => handleChange(name, value)}
              options={visibilityTypes}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.hidden && Boolean(suggestedReadingValues.errors.hidden)}
              errorMessage={suggestedReadingValues.touched.hidden && suggestedReadingValues.errors.hidden} />
          </div>
          <div>
            <MultiSelectField
              name="sections"
              label="Sections"
              allOption={true}
              value={suggestedReadingValues?.values?.sections || []}
              onChange={(name, value) => handleChange(name, value)}
              options={sectionsDropdown ? sectionsDropdown?.map(option => ({ label: option?.listItemValue, value: option?.listItemID })) : []}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.sections && Boolean(suggestedReadingValues.errors.sections)}
              errorMessage={suggestedReadingValues.touched.sections && suggestedReadingValues.errors.sections} />
          </div>
          <div>
            <MultiSelectField
              name="fundTypes"
              label="Fund Type"
              allOption={true}
              value={suggestedReadingValues?.values?.fundTypes || []}
              onChange={(name, value) => handleChange(name, value)}
              options={fundTypes ? fundTypes : []}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.fundTypes && Boolean(suggestedReadingValues.errors.fundTypes)}
              errorMessage={suggestedReadingValues.touched.fundTypes && suggestedReadingValues.errors.fundTypes} />
          </div>
          <div className="suggested-FundsSelect">
            <MultiSelectField
              name="regardingFunds"
              label="Funds"
              allOption={true}
              value={suggestedReadingValues?.values?.regardingFunds || []}
              onChange={(name, value) => handleChange(name, value)}
              options={fundNames ? fundNames : []}
              required={true}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.regardingFunds && Boolean(suggestedReadingValues.errors.regardingFunds)}
              errorMessage={suggestedReadingValues.touched.regardingFunds && suggestedReadingValues.errors.regardingFunds} />
          </div>
          <div>
            <MultiSelectField
              name="fundManagement"
              label="Fund Management"
              allOption={true}
              value={suggestedReadingValues?.values?.fundManagement || []}
              onChange={(name, value) => handleChange(name, value)}
              options={fundManagementDropdown ? fundManagementDropdown?.map(option => ({ label: option?.listItemValue, value: option?.listItemID })) : []}
              required={!(fundManagementField())}
              disabled={fundManagementField()}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.fundManagement && Boolean(suggestedReadingValues.errors.fundManagement)}
              errorMessage={suggestedReadingValues.touched.fundManagement && suggestedReadingValues.errors.fundManagement}
              helperText={"To enable this field, please select 'Fund Manager Dashboard' in Sections field"} />
          </div>
          <div>
            <SelectField
              name="investorSubscriptionStatus"
              label="Investor Subscription Status"
              value={suggestedReadingValues?.values?.investorSubscriptionStatus}
              onChange={(name, value) => handleChange(name, value)}
              options={subscriptionStatusDropdown ? subscriptionStatusDropdown?.map(option => ({ label: option?.listItemValue, value: option?.listItemID })) : []}
              required={!(subscriptionStatusField())}
              disabled={subscriptionStatusField()}
              onBlur={suggestedReadingValues.handleBlur}
              error={suggestedReadingValues.touched.investorSubscriptionStatus && Boolean(suggestedReadingValues.errors.investorSubscriptionStatus)}
              errorMessage={suggestedReadingValues.touched.investorSubscriptionStatus && suggestedReadingValues.errors.investorSubscriptionStatus}
              helperText={"To enable this field, please select 'Investor Dashboard' in Sections field"} />
          </div>
         
        </div>
        <div>
        <div className='mobile-res'>
            <FileUploadBtn
              name="articleUploadFileName"
              label='UPLOAD PDF FILE'
              fileType={uploadFileTypes.PDF}
              defaultFile=""
              clearFile={clearFile}
              onChange={(name,value) => handleFileUpload(name,value)}
              disabled={!(suggestedReadingValues?.values?.type === articleTypesEnum.UPLOAD_FILE)} /> 
          </div>
          </div>
          <div className='btns-suggested'>
            <div className='margin-right-10'>
              <ActionButton
                variant='outlined'
                className='btn-primary'
                label="Cancel"
                disabled={loading}
                onClick={onCancelClick}
                icon={<CancelIcon/>}>
              </ActionButton>
            </div>
            <div>
              <ActionButton
                variant='contained'
                className='btn-primary'
                loading={loading}
                label={suggestedReadingValues.values.suggestedArticleID === 0 ? "Upload" : "Update"}
                onClick={suggestedReadingValues.handleSubmit}
                icon={<CloudUploadIcon />}>
              </ActionButton>
            </div>
          </div>
        <div>
        {selectedReadingID !== 0 && typeof suggestedReadingValues.values.articleUploadFileName === 'string' && suggestedReadingValues.values.articleUploadFileName !== "" ? (
            <p>Existing File : {typeof suggestedReadingValues.values.articleUploadFileName === "string" ? suggestedReadingValues.values.articleUploadFileName.split("windows.net/")[1] : suggestedReadingValues.values.articleUploadFileName}</p>
        ) : null}
        </div>
      </form>
      <ToastContainer />
      {
        validationPopup && 
        <ValidationPopup
           open={validationPopup}
           data={suggestedReadingValues.values}
           onAccept={createAndUpdateArticle}
           onClose={closeValidationpopup} />
      }
    </div>
  )
}

export default UploadReadings;