import React, { useEffect, useState } from 'react'
import KycDetails from './kyc-aml/KycDetails'
import AmlDetails from './kyc-aml/AmlDetails'
import VDRoom from '../../../common/VirtualDataRoom/VDRoom';
import DiscussionBox from '../../../KYCVerification/components/DiscussionBox';
import secureLocalStorage from 'react-secure-storage';
import { Button } from '@mui/material';
import { KycAMLStatusEnum, KycAmlStuatus } from '../../../KYCVerification/enum';
import { getkyccomments } from '../../../KYCVerification/services/services';

const KycAmlDetails = (props) => {

  //#region props
  const { investorDetails,investorTypeName,investorTypesValues, readOnly } = props;
  
  //#region variables
  const [showComments, setshowComments] = useState(false);
  const [previousComments,setPreviousComments] = useState([]);

  //#region click events
  const handleComments = () => {
    const details = {
      "id": investorDetails?.personalInformation?.userId
    }
    secureLocalStorage.setItem("KYCInvestorDetails", details);
    setshowComments(!showComments);
  }

  //#region api get calls
  const getComments = async () => {
    const data = await getkyccomments(investorDetails?.personalInformation?.userId);
    if (data?.responseCode === 200) {
      const reversedData = data.responseData;
      setPreviousComments(data.responseData);
    } else {
      // Handle error if needed
    }
  }

  //#region useefffect
  useEffect(()=>{
    getComments();
  },[])


  //#region return
  return (
    <div className='child-margin-15'>
        <div>
          <KycDetails investorDetails={investorDetails} investorTypeName={investorTypeName} isViewOnly={readOnly}/>
        </div>
        <div className='margin-top-15'>
          <AmlDetails investorDetails={investorDetails} investorTypeName={investorTypeName}  isViewOnly={readOnly}/>
        </div>
        { 
        !readOnly && 
        <div>
        <>
            {
              (investorDetails?.personalInformation?.kycStatusName !== KycAMLStatusEnum.VERIFIED || 
                investorDetails?.personalInformation?.amlStatusName !== KycAMLStatusEnum.VERIFIED
              ) && previousComments?.length > 0 &&
              <div>
                <Button
                  onClick={handleComments}
                  variant="contained"
                  className="btn-primary">
                  {showComments ? 'Hide Comments' : 'Show Comments'}
                </Button>
              </div>
            }
           {
              showComments &&
              <div item xs={12} className='whiteCard margin-top-10' sx={{ marginTop: '10px' }}>
                <DiscussionBox />
              </div>
            }
            <div className='white-card margin-top-10'>
                <VDRoom 
                  VDRType={'KYCAML'}
                  cnrtFundID={0}
                  crntSubscriptionID={0}
                  crntInvestorID={investorDetails?.personalInformation?.userId}
                  hideMenu={false} />
            </div>
          </>
        </div>
        }
    </div>
  )
}

export default KycAmlDetails