import TextInput from "../../../common/TextInput/TextInput";
import Text from "../../../common/Text/Text";
import ActionButton from "../../../common/ActionButton/ActionButton";
import './styles.css';
import { useState, useEffect, useRef } from "react";
import secureLocalStorage from "react-secure-storage";
import InputMultiSelect from "../../../common/Multi-Select-Dropdown/InputMultiSelect";
import MultipleSelectCheckmarks from "../../../common/Multi-Select-Dropdown/MultipleSelect";
import { getRoles } from "../../../FundManagerDashboard/Services/FundNewsService";
import { Input } from "@mui/material";
import Button from '@mui/material/Button';
import AppTextInput from "../../../common/TextInput/AppTextField";
import { validationsTypeEnum, InputTypes, validationMessages, textFormatTypeEnum } from "../../../common/TextInput/appInputenum";
import { AppDashboards } from "../../../../utils/enum";
import { ToastContainer, toast } from 'react-toastify';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import CheckBoxField from "../../../common/input-fields/CheckBoxField";

const ManagementTeam = (props) => {

  //#region props
  const { allManagementDetails } = props;

  //#region initialdata
  const initialManagementTeamDetails = {
    // managementTeamStaff:'',
    // title: '',
    // description: '',
    ...props?.data,
  };

  //#region variables
  const [fundManagementTeams, setFundManagementTeams] = useState(initialManagementTeamDetails)
  const [allMemberDetails,setAllMemberDetails] = useState(allManagementDetails);
  const componentRef = useRef(null);
  const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
  const [checkIfValid, setcheckIfValid] = useState(false);

  //#region change events
  const handleChange = (field, value) => {
    const currState = { ...fundManagementTeams };
    if (field === 'managementTeamStaff') {
      currState[field] = value;
      if (value) {
        const selectedUser = props?.managementStaffData.find((user) => user.value === value);
        if (selectedUser) {
          currState['title'] = selectedUser.title || ''; // Set roleName in the title field
          currState['description'] = selectedUser.description || ''; // Set brief Description for the selected name
        }
      }
    } else {
      currState[field] = value;
    }
    if(field === "authorizedSignatory"){
      setAllMemberDetails(prevDetails =>
        prevDetails.map(item => {
          if (item.fundManagementTeamID === fundManagementTeams.fundManagementTeamID) {
            return {
              ...item, 
              authorizedSignatory: value, 
            };
          } else {
            return item; 
          }
        })
      );      
    }
    setFundManagementTeams(currState);
  }

  //#region validations
  let count = 0;   //to Show Toast Message only once
  const checknoofValidFields = (isValid, name, defaultValue) => {
    count = ++count;
    const TotalFiledsToValidate = 1;
    noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
    if (noofValidFields['validfields'] === TotalFiledsToValidate) {
      noofValidFields['validfields'] = 0;
      const authorizedCount = allMemberDetails?.filter(item => item.authorizedSignatory === true).length;
      if(allMemberDetails?.some((item)=> item.authorizedSignatory === true)){
        if(authorizedCount <= 1){
          props?.handleClickSave(props?.index, fundManagementTeams, props?.getFundDetails)
        }
        else{
          toast.warning("Authorized signatory is already exists for this fund",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
        }
      }
      else{
        if(props.funddata?.isFirmAsGP){
          toast.warning("Atleast One Authorized signatory is mandatory",
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
        }
        else{
          props?.handleClickSave(props?.index, fundManagementTeams, props?.getFundDetails)
        }
      }
      count = 0;
    }
    else {
      if (count === TotalFiledsToValidate) {
        toast.warning("Please verify the input fields",
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored", style: { zIndex: 9999 } });
        count = 0;
      }
    }
  };

  const handleSave = () => {
    setcheckIfValid(true);
    noofValidFields['validfields'] = 0;
  }

  //#region return
  return (
    <div ref={componentRef}>
      <div className="fundRow no-margin" style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <div>
            <div className="btns">
              {props?.editable ? (
                <>
                  <div>
                    <ActionButton variant="outlined" className="btn-primary"
                      onClick={() => props?.handleClickCancel(props?.index)}
                      label="CANCEL"
                      startIconName="Cancel"
                      styleProps={{
                        padding: '8px 26px'
                      }}
                    >

                    </ActionButton>
                  </div>
                  <div className="margin-left-5"></div>
                  <ActionButton variant="contained" className="btn-primary"
                    onClick={() => handleSave()}
                    label="SAVE"
                    startIconName="Save"
                    styleProps={{
                      padding: '8px 26px'
                    }}
                  >

                  </ActionButton>
                </>
              ) : (
                <ActionButton variant="contained" className="btn-primary"
                  onClick={() => props?.handleClickEdit(props?.index)}
                  label="EDIT"
                  startIconName='Edit'
                  styleProps={{
                    padding: '8px 26px'
                  }}
                >

                </ActionButton>
              )}


            </div>
          </div>
      </div>
      <div className="fundRow"><Text label="Management Team" /></div>
      <div className="fundRow">
        <div className="fundDetail">
          <AppTextInput
            type={InputTypes.SELECT}
            name="managementTeamStaff"
            label="Name"
            defaultValue={fundManagementTeams?.managementTeamStaff}
            options={props?.managementStaffData
              ? props?.managementStaffData?.filter(a => a.userRoleName !== AppDashboards.INV_DASHBOARD)
                ?.map(option => ({ label: option?.label, value: option?.value, isdisabled: option?.disabled, title: option?.title, description: option?.briefDescription }))
              : []
            }
            disabled={!props.editable}
            onChange={(name, value) => handleChange(name, value)}
            required={true}
            validateField={true}
            validationType={validationsTypeEnum.REQUIRED}
            validationMessage={validationMessages.REQUIRED}
            checkIfValid={checkIfValid}
            checknoofValidFields={checknoofValidFields}
            setcheckIfValid={setcheckIfValid} />
        </div>
        <div className="fundDetail">
          {
            props.funddata?.isFirmAsGP && <CheckBoxField 
            name="authorizedSignatory" 
            label="Authorized Signatory"
            value={fundManagementTeams?.authorizedSignatory}
            defaultChecked={fundManagementTeams?.authorizedSignatory}
            onChange={(name, value) => handleChange(name, value)}
            disabled={!props.editable} />
          }
        </div>
      </div>
      <div className="fundRow">
        <AppTextInput
          variant="outlined"
          multiline={true}
          rows={4}
          name="description"
          defaultValue={fundManagementTeams?.description}
          disabled={true}
          placeholder="Brief Description their role, responsibility and fund history*."
          onChange={(name, value) => handleChange(name, value)}
        // errormessage={errors.description || undefined}
        />
      </div>
      <div className="fundRow">
        <div className="fundDetail">
          <input
            placeholder="Title"
            value={fundManagementTeams?.title}
            name='title'
            editable={false}
            className="InputFieldClass-FundNews"
            onChange={(event) => handleChange('title', event.target.value)}
            disabled />
        </div>
      </div>
    </div>
  )
}

export default ManagementTeam;