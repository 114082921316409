import React, { useEffect, useState } from 'react'
import StepPopup from '../popups/StepPopup';
import { SubscribeFund } from '../services/services';
import { CircularProgress } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { ToastContainer, toast } from 'react-toastify';
import { questionPatterns, subscriptionQuestionData, stepsToShowWhile, subscriptionValidateFields } from '../services/apiDataTemplate';
import { convertStringToArrayWithEmptyArray, trimAndLowercaseArray } from '../../common/Functions/ConvertStringtoArray';
import { subscriptioToastMessages } from '../../../utils/AppConstants';
import OfflineConfirmationDialog from '../popups/OfflineConfirmationDialog';

//#This component is to manage all subscription steps
const StepContainer = (props) => {

  //#region props
  const { type, 
          investorSubscriptionDetails, 
          fundID, investorId, 
          setDisableSbtButton, 
          subscriptionId, 
          getQuestionAnswerDetails, 
          sbtLoading, setSbtLoading, 
          submitSubscriptionAnswers } = props;

  //#region variables
  const [ispopUpOpen, setIspopUpOpen] = useState(false);
  const [steps, setSteps] = useState();
  const [questionsDetails, setQuestionsDetails] = useState();
  const [currStepDetails, setCurrStepDetails] = useState();
  const [disableStepsFrom, setDisableStepsFrom] = useState(null);
  const [validationErrors, setValidationErrors] = useState({});
  const [offlineConfirmationPopupOpen, setofflineConfirmationPopupOpen] = useState(false)

  //#region functions
  const showThisQuestion = (crntQuestion) => {

    if (crntQuestion.parentQuestionID === 0) {
        return true;
    }

    var parentQuestion = questionsDetails.find(u => u.questionBankID === crntQuestion.parentQuestionID);

    if (parentQuestion && crntQuestion.checkParentAnswer) {
        const answersArray = convertStringToArrayWithEmptyArray(crntQuestion?.showIfParentAnswerIDIsIn)

        if (answersArray?.length > 0) {
            return (
                answersArray.some(answer => parentQuestion.multiChoiceQuestionAnswerIDs?.includes(answer)) ||
                answersArray.includes(parentQuestion.questionAnswerID)
            );
        }
        else {
            const parentAnswerArray = convertStringToArrayWithEmptyArray(parentQuestion.userProvidedAnswer.trim().toLowerCase())
            const childAnswerArray = convertStringToArrayWithEmptyArray(crntQuestion.showIfParentAnswerIs.trim().toLowerCase());

            const trimmedParentAnswerArray = trimAndLowercaseArray(parentAnswerArray);
            const trimmedChildAnswerArray = trimAndLowercaseArray(childAnswerArray);

            const result = trimmedChildAnswerArray?.includes(trimmedParentAnswerArray[0]);
            return result
        }

    }
    return false;
}

  const checkStepFilledStatus = (currStep) => {
    const stepDetails = questionsDetails?.filter((item) => item?.step === currStep?.stepID);
    if (stepDetails?.length > 0) {
      const isQuestionsFilled = stepDetails?.every((item) => {
        if (item.setValidations === true && showThisQuestion(item)) {
          const hasValidationError = Object.keys(validationErrors).includes(String(item?.questionBankID));
          let isValid = false;
          isValid = (
            (item.userProvidedAnswer !== null && item.userProvidedAnswer !== "") ||
            (item.multiChoiceQuestionAnswerIDs !== null && item.multiChoiceQuestionAnswerIDs.length > 0) ||
            (item.questionAnswerID !== null && item.questionAnswerID !== 0)
          );
          if (hasValidationError) {
            isValid = false;
          }
          if (type === stepsToShowWhile.SUBSCRIBING && item?.questionName.trim() === subscriptionValidateFields.COMMITED_CAPITAL) {
            if (parseInt(item.userProvidedAnswer) < investorSubscriptionDetails?.fundBasicDetails?.minimumInvestmentAmount || parseInt(item.userProvidedAnswer) > investorSubscriptionDetails?.fundBasicDetails?.fundSize) {
              isValid = false;
            }
          }
          return isValid;

        }
        return true; // If setValidations is not true, consider the item valid
      });

      return isQuestionsFilled || false;
    }
    return false;
  }


  //#region click events
  const onispopUpOpen = (stepDetails) => {
    if (stepDetails.stepNumber > (disableStepsFrom ?? 100000)) {
      return false;
    }
    else {
      setCurrStepDetails(stepDetails);
      setIspopUpOpen(true);
    }
  }

  const onClosePopup = () => {
    setIspopUpOpen(false);
  }

  //#region useeffect
  useEffect(() => {
    let conditionMet = false;
    questionsDetails
      ?.forEach((question, index) => {
        if (
          question?.stopIfAnswerIDIs !== 0 && (
            question?.stopIfAnswerIDIs === question?.questionAnswerID ||
            question?.multiChoiceQuestionAnswerIDs?.includes(question?.stopIfAnswerIDIs))
        ) {
          const questionIndex = questionsDetails.findIndex((item) => item?.questionBankID === question?.questionBankID);

          const stepToDisable = steps.find(u => u.stepID === questionsDetails[questionIndex]?.step)?.stepNumber;
          if (stepToDisable !== undefined) {
            setDisableStepsFrom(stepToDisable);
            conditionMet = true;
          }
        }
      });

    if (!conditionMet) {
      setDisableStepsFrom(null);
    }
  }, [questionsDetails, steps]);

  useEffect(() => {
    if (investorSubscriptionDetails && type === stepsToShowWhile.USER_PROFILE_SAVE) {
      setSteps(investorSubscriptionDetails?.questionBankSteps);
      setQuestionsDetails(investorSubscriptionDetails?.userQuestionAndAnswers)
      if (investorSubscriptionDetails?.questionBankSteps) {
        return;
      }
      else {
        setSteps([]);
        toast.error(subscriptioToastMessages.STEPS_LOADING_FAILED,
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
    else if (investorSubscriptionDetails) {
      setSteps(investorSubscriptionDetails?.questionBankAndSteps?.questionBankSteps);
      setQuestionsDetails(investorSubscriptionDetails?.questionBankAndSteps?.userQuestionAndAnswers)
      if (investorSubscriptionDetails?.questionBankAndSteps?.questionBankSteps) {
        return;
      }
      else {
        setSteps([]);
        toast.error(subscriptioToastMessages.STEPS_LOADING_FAILE,
          { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
      }
    }
    else {
      setSteps(null);
    }
  }, [investorSubscriptionDetails])

  useEffect(() => {
    if (type === stepsToShowWhile.OFFLINE_SUBSCRIPTION) {
      //const emptyObject = []
      const firstIncompleteStep = questionsDetails
        ?.filter(step => step.isActive && step.setValidations && step.userProvidedAnswer === '' && step.showIfParentAnswerIDIsIn === '' && step.questionAnswer.length === 0) // Filter incomplete steps
        .sort((a, b) => a.step - b.step)[0];
      if (firstIncompleteStep) {
        if (sbtLoading) {
          setofflineConfirmationPopupOpen(true)
          setSbtLoading(false)
        }
      } else if (sbtLoading === true) {
        submitSubscriptionAnswers(questionsDetails);
      }
    } else {
      const hasIncompleteSteps = steps?.some(item => !checkStepFilledStatus(item));
      if (sbtLoading) {
        if (Object.keys(validationErrors).length > 0 || hasIncompleteSteps) {
          setSbtLoading(false);
          toast.warning(subscriptioToastMessages.VALIDATION_CHECK,
            { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        else {
          submitSubscriptionAnswers(questionsDetails);
        }
      }
    }
  }, [sbtLoading])

  useEffect(() => {
    if (setDisableSbtButton) {
      setDisableSbtButton(disableStepsFrom ? true : false)
    }
  }, [disableStepsFrom])

  //#region return
  return (
    <div className='margin-bottom-20'>
      {
        steps ?
          steps?.length > 0 ?
            <div className='display-row-items-percent-50'>
              {
                steps?.map((item) => {
                  return (
                    <div key={item?.stepID}
                      className={(item.stepNumber > (disableStepsFrom ?? 10000))
                        ? 'disable-subscription-step-box ml1'
                        : 'subscription-step-box ml1'}
                      onClick={() => { onispopUpOpen(item) }}>
                      <div className='margin-left-10'>
                        {
                          checkStepFilledStatus(item) ?
                            <div className='sub-cross-tick-icon'>
                              <img src="/subscription/check-circle-fill1-wght400-grad0-opsz48.svg" alt="Checkmark Icon"></img>
                              {/*  <CheckCircleIcon /> */}
                            </div> :
                            <div className='sub-cross-check-icon'>
                              <CancelIcon />
                            </div>
                        }
                      </div>
                      <div className='ml1'>
                        <div className='color-gray'>{item?.stepName}</div>
                        <div>{item?.stepTitle}</div>
                      </div>
                    </div>
                  )
                })
              }
            </div> :
            <div className='align-items-center margin-top-30'>
              <b>Unable to load the steps</b>
            </div>
          :
          <div className='align-items-center margin-top-30'>
            <CircularProgress />
            <b> Please wait while loading subscription steps...</b>
          </div>
      }
      {
        offlineConfirmationPopupOpen && <OfflineConfirmationDialog currStepDetails={currStepDetails} popupopen={setIspopUpOpen}
          onpopupopen={onClosePopup} questionDetais={questionsDetails} steps={steps}
          setCurrStepDetails={setCurrStepDetails} disableStepsFrom={disableStepsFrom}
          setDisableStepsFrom={setDisableStepsFrom}
          investorId={investorId}
          getQuestionAnswerDetails={getQuestionAnswerDetails}
          investorSubscriptionDetails={investorSubscriptionDetails}
          fundDeatils={investorSubscriptionDetails?.fundBasicDetails}
          submitSubscriptionAnswers={submitSubscriptionAnswers}
          setValidationErrors={setValidationErrors}
          open={offlineConfirmationPopupOpen} onClose={() => setofflineConfirmationPopupOpen(false)} />
      }
      {
        ispopUpOpen &&
        <StepPopup
          open={ispopUpOpen}
          onClose={onClosePopup}
          type={type}
          steps={steps}
          subscriptionId={subscriptionId}
          currStepDetails={currStepDetails}
          setCurrStepDetails={setCurrStepDetails}
          questionsDetails={questionsDetails}
          setQuestionsDetails={setQuestionsDetails}
          disableStepsFrom={disableStepsFrom}
          setDisableStepsFrom={setDisableStepsFrom}
          investorId={investorId}
          getQuestionAnswerDetails={getQuestionAnswerDetails}
          investorSubscriptionDetails={investorSubscriptionDetails}
          fundDeatils={investorSubscriptionDetails?.fundBasicDetails}
          setValidationErrors={setValidationErrors} />
      }
      <ToastContainer />
    </div>
  )
}

export default StepContainer