// yupCustomMethods.js
import * as yup from 'yup';
import { foulWords, reservedWords } from '../../../registration-profiling/foulwords';
import dayjs from 'dayjs';

export const addCustomYupMethods = () => {

  //#region username   
  yup.addMethod(yup.string, 'customUserName', function (errorMessage) {
    return this.test('test-custom-username', errorMessage, function (value) {
      const { path, createError } = this;
      const trimmedValue = value ? value.replace(/\s/g, '') : '';
      
      const isNotFoulWord = !isFoulWord(value);
      const isNotEmpty = trimmedValue !== '';
      const isMinLengthValid = trimmedValue.length >= 6;
      const isMaxLengthValid = trimmedValue.length <= 35;
      const isValidCharacters = /^[a-zA-Z0-9]*_?[a-zA-Z0-9]*$/.test(value);
      const hasAlphabet = /[a-zA-Z]/.test(trimmedValue);
      const hasRepetitiveNumbers = !/(.)\1{5,}/.test(trimmedValue);
      const doesNotBeginWithWhitespace = trimmedValue.charAt(0) !== ' ';
      const doesNotEndWithWhitespace = trimmedValue.charAt(trimmedValue.length - 1) !== ' ';

      return (
        isNotFoulWord &&
        isNotEmpty &&
        isMinLengthValid &&
        isMaxLengthValid &&
        isValidCharacters &&
        hasAlphabet &&
        hasRepetitiveNumbers &&
        doesNotBeginWithWhitespace &&
        doesNotEndWithWhitespace
      ) || createError({ path, message: errorMessage });
    });
  });

  //#region email   
  yup.addMethod(yup.string, 'customEmail', function (errorMessage) {
    return this.test('custom-email', errorMessage, function (value) {
      const { path, createError } = this;
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const isValid = value ? emailRegex.test(value) : false;
      return isValid || createError({ path, message: errorMessage });
    });
  });

  //#region password
  yup.addMethod(yup.string, 'customPassword', function (errorMessage) {
    return this.test('test-custom-password', errorMessage, function (value) {
      const { path, createError } = this;

      const lowercaseRegex = /[a-z]/;
      const uppercaseRegex = /[A-Z]/;
      const digitRegex = /\d/;
      const symbolRegex = /[@#$%^&*()_+={}\[\]:;"'<>,.?/|\\\-~]/;
      const trimmedValue = value ? value.replace(/\s/g, '') : '';

      const hasLowercase = lowercaseRegex.test(value);
      const hasUppercase = uppercaseRegex.test(value);
      const hasDigit = digitRegex.test(value);
      const hasSymbol = symbolRegex.test(value);
      const doesNotBeginWithWhitespace = value.charAt(0) !== ' ';
      const doesNotEndWithWhitespace = value.charAt(value.length - 1) !== ' ';

      const isValid = 
        ((hasLowercase && hasUppercase && hasDigit) ||
        (hasLowercase && hasUppercase && hasSymbol) ||
        (hasLowercase && hasDigit && hasSymbol) ||
        (hasUppercase && hasDigit && hasSymbol)) &&
        value.length >= 8 &&
        value.length <= 16 &&
        doesNotBeginWithWhitespace &&
        doesNotEndWithWhitespace;

      return isValid || createError({ path, message: errorMessage });
    });
  });

  //#region future date
  yup.addMethod(yup.date, 'noFutureDate', function (errorMessage) {
    return this.test('test-no-future-date', errorMessage, function (value) {
      const { path, createError } = this;
      const isValid = !value || dayjs(value).isBefore(dayjs(), 'day') || dayjs(value).isSame(dayjs(), 'day');
      return isValid || createError({ path, message: errorMessage });
    });
  });
  
};

const isFoulWord = (word) => {
    const foulWordss = [...foulWords]; 
    const reservedWordsList = [...reservedWords]; 
    const foulWordsList = [...foulWordss, ...reservedWordsList];
    const sanitizedWord = word.replace(/\s/g, '').toLowerCase();
    return foulWordsList.some(foulWord => sanitizedWord.includes(foulWord));
  };