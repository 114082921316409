import * as React from 'react';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment/moment';
import '../TextInput/styles.css';
import propTypes from 'prop-types';

const DateField = (props) => {

  //#region props
  const {
    name,
    label,
    value,
    onChange,
    onBlur,
    required,
    disabled,
    disableFuture,
    error,
    errorMessage,
    helperText,
    readOnly
  } = props;

  //#region change events
  const handleChange = (selectedDate) => {
    const  value  = new Date(selectedDate);
    onChange(name,value)
  }
 
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
            disableFuture={disableFuture}
            name={name}
            label={label}
            format="MM/DD/YYYY"
            value={dayjs(moment(value).format("YYYY-MM-DD")) || ''}
            onChange={handleChange}
            disabled={disabled}
            disableOpenPicker={readOnly || disabled}
            error={error} // Pass the error prop directly to the DatePicker
            sx={{lineHeight:'1.5em !important'}}
            slotProps={{
                textField: {
                    variant: "standard",
                    //required: required,
                    InputLabelProps: {
                        shrink: true,
                        focused: true,
                    },
                    helperText : error ? errorMessage : helperText, // Display errorMessage as helperText if error is true
                    readOnly: readOnly,
                    error: error, // Pass the error prop directly to the TextField
                    disabled: disabled,
                    fullWidth: '100%',
                    required:required
                }
            }}/>
    </LocalizationProvider>
  )
}

DateField.propTypes = {
    type: propTypes.string,
    onChange: propTypes.func.isRequired,
    variant: propTypes.oneOf(['standard', 'filled', 'outlined']),
    required: propTypes.bool,
    value: propTypes.string,
    placeholder: propTypes.string,
    label: propTypes.string,
    disabled: propTypes.bool,
    options: propTypes.array,
    name: propTypes.string,
    size: propTypes.string,
    errorMessage: propTypes.string,
    helperText: propTypes.string,
    disableFuture: propTypes.bool,
    readOnly: propTypes.bool,
    error: propTypes.bool // Add error prop type
};

DateField.defaultProps = {
    type: 'select',
    variant: 'standard',
    size: 'small',
    required: false,
    value: '',
    placeholder: '',
    label: '',
    options: [],
    disabled: false,
    name: '',
    errorMessage: '',
    helperText: '',
    disableFuture: false,
    readOnly: false,
    error: false // Set default value for error prop
};

export default DateField;
