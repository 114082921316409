import { Button, Grid, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { addCompare, removeCompare, updateAllFunds } from '../../../state/slices/MarketPlaceSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getAllFunds } from '../../FundManagerDashboard/Services/FundNewsService';
import secureLocalStorage from 'react-secure-storage';
import { checkFeaturePermissions } from '../../../utils/common';
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../utils/enum';
import { useNavigate } from 'react-router-dom';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import { addFavourite, removeFavourite } from '../../../state/slices/MarketPlaceSlice';
import { updateFavouriteFund } from '../services/mpservices';
import { fundSubscriptionStatues } from '../../CODashboard/Services/apiDataTempate';
import { fundTypesEnum } from '../jsonData';
import FavoriteIcon from '@mui/icons-material/Favorite';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
const FundCardView = (props) => {

  //#region props
  const { fundDetails, allFunds, allInvestors, comparefunds,getFunds,favoritefunds } = props;


  //#region variables
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const fundDescriptionMaxLength = 150;

 
  const subscribebtn = secureLocalStorage.getItem("userrole") === AppDashboards.INV_DASHBOARD ?
    (fundDetails?.isFundMatured
      || secureLocalStorage.getItem("InvestorFundIDs")?.includes(fundDetails?.fundID) || allInvestors.some(u =>
        u.fundID === fundDetails?.fundID &&
        u.subscriptionStatus === fundSubscriptionStatues.Awaiting_Online_Processing)
      || fundDetails.fundStatusName === 'Closed to New Investors' || fundDetails.fundStatusName === 'Launching Soon' 
    ) :
    true;

  //  (item?.isFundMatured )

  //#region click events
  const handleAddCompare = (fund) => {
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
        return { ...item, compare: true };
      }
      return item;
    });
    const compareFund = updatedFunds.find((item) => item.fundID === fund.fundID)
    dispatch(updateAllFunds({ value: updatedFunds }));
    dispatch(addCompare({ fund: compareFund }));
  }

  const handleRemoveCompare = (fund) => {
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
        return { ...item, compare: false };
      }
      return item;
    });
    const fundID = fund.fundID;
    dispatch(updateAllFunds({ value: updatedFunds }));
    dispatch(removeCompare({ fundID: fundID }));
  }

  const handleAddFavourite = async (fund) => {
    const data = await updateFavouriteFund(fund.fundID, true);
    const updatedFunds = allFunds.map(item => {
      if (item.fundID === fund.fundID) {
        return { ...item, favourite: true };
      }
      return item;
    });
    const favFund = updatedFunds.find((item) => item.fundID === fund.fundID)
   /*  dispatch(updateAllFunds({ value: updatedFunds })); */
    if (data.responseCode === 200) {
      dispatch(addFavourite({ fund: favFund }));
      getFunds();
    }
    else {
      /* console.log("Unable to add Fund") */
    }
  }

  const handleRemoveFavourite = async (fund) => {
    const data = await updateFavouriteFund(fund.fundID, false);
    if (data.responseCode === 200) {
      const updatedFunds = allFunds.map(item => {
        if (item.fundID === fund.fundID) {
          return { ...item, favourite: false };
        }
        return item;
      });
      const fundID = fund.fundID;
     /*  dispatch(updateAllFunds({ value: updatedFunds })); */
      getFunds();
      dispatch(removeFavourite({ fundID: fundID }));
    }
    else {
      /* console.log("Unable to remove Fund") */
    }
  }

  const handlesubscribe = (item, itemId, fundname, event) => {
    if (event) {
      event.stopPropagation();
    }
    secureLocalStorage.setItem("FundName", fundname);
    secureLocalStorage.setItem("FundId", itemId);
    secureLocalStorage.setItem("FundSPIcon", item.iconImage);
    secureLocalStorage.setItem("fundDescription", item.fundDescription);

    //#need to find subscription status
    const investor = allInvestors.find(u =>
    (u.fundID === fundDetails?.fundID &&
      u.investorID === secureLocalStorage.getItem("userId"))
    );

    const subscriptionId = investor ? investor?.subscriptionsID : 0;

    secureLocalStorage.setItem("subscriptionId", subscriptionId);

    /*  console.log(item); */
    /*   */
    navigate('/FundSubscriptionPage', { state: { FundId: { itemId } } });
    window.scrollTo(0, 0);
  };

  const handleViewDetail = (item, itemIdObj, fundname, fundStatusName, disableSubscriptionButton, event) => {
    if (event) {
      event.stopPropagation();
    }
    let parsedFundId;
    try {
      parsedFundId = JSON.parse(itemIdObj);
    } catch (error) {
      console.error("Error parsing fundDetail:", error);
    }
    secureLocalStorage.setItem("FundId", parsedFundId);
    secureLocalStorage.setItem("FundName", fundname);
    const havepermissiontoread = checkFeaturePermissions(RolesAndPermissions.FUND, FeatureAccess.READ);
    if (secureLocalStorage.getItem('userrole') === AppDashboards.INV_DASHBOARD) {
      // debugger
      secureLocalStorage.setItem('FundTypeName', (item.fundTypeName))
      /* navigate('/fund-details', { parsedFundId }, { fundStatusName }); */
      navigate('/fund-details', {
        state: {
          FundID: parsedFundId,
          InvestorID: secureLocalStorage.getItem("userId"),
          SubscriptionID: 0,
          navigatefrommarketplace: true,
          disableSubscriptionButton: disableSubscriptionButton,
          FundType: fundDetails?.fundTypeName,
          renderSubscribeBtnLabel : renderSubscribeBtnLabel()
        }
      });

    } else if (havepermissiontoread) {

      const SelectedFund = {
        "FundMaturity": item.isFundMatured,
        "FundTermination": item.fundStatusName === 'Terminated',
        BGImage: item.bgImage,
        IconImage: item.iconImage,
      }
      secureLocalStorage.setItem("navigatingFromMarketPlace", true);
      secureLocalStorage.setItem("selectedFund", SelectedFund);
      navigate('/marketplace/OverviewPage', { parsedFundId });
    }

    secureLocalStorage.setItem("FundId", JSON.stringify(parsedFundId));
    secureLocalStorage.setItem('fundStatusName', fundStatusName);
    window.scrollTo(0, 0);
  };

  const onInvestmentFundClick = () => {
    secureLocalStorage.setItem("FundId", fundDetails.fundID);
    if (allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.APPROVED))) {
      navigate('/Investor-Overview-Page', {
        state: {
          FundId: fundDetails.fundID,
          subscriptionId: fundDetails.subscriptionID
        }
      });
    }
    else {
      secureLocalStorage.setItem("FundId", fundDetails.fundID);
      navigate('/subscription-details', {
        state: {
          FundId: fundDetails.fundID,
          subscriptionId: fundDetails.subscriptionID,
          selectedRow: fundDetails,
        }
      });
    }
  }

  //#subscribe button text

  const renderSubscribeBtnLabel = () => {
    if(allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.COMPLIANCE_REVIEW)
      || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.FM_REVIEW)
      || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.APPROVED)
    )){
     return 'MY INVESTMENT'
    }
    else if (allInvestors.some(u =>
      u.fundID === fundDetails?.fundID &&
      u.subscriptionStatus === fundSubscriptionStatues.Awaiting_Online_Processing)) {
      return 'SUBSCRIBED'
    }
    else if (allInvestors.some(u =>
      u.fundID === fundDetails?.fundID &&
      u.subscriptionStatus === fundSubscriptionStatues.INVESTOR_SIGN_PENDING &&
      u.isOfflineSubscription !== true)) {
      return "SUBSCRIBE"
    }
    else if (allInvestors.some(u =>
      u.fundID === fundDetails?.fundID &&
      u.subscriptionStatus === fundSubscriptionStatues.REJECTED)) {
      return 'SUBSCRIBE'
    }
    else {
      return 'SUBSCRIBE'
    }
  }
  

  //#region return
  return (
    <div className={fundDetails?.isFundMatured === true || fundDetails?.fundTerminated === true ? "card-view-grayed-out" : 'card-view'}>
      <div className='card-img-container'>
        <img
          className='bg-img-container'
          src={fundDetails?.bgImage || '/DefaultBackgroundImage.png'}
            alt="Background"
            onError={(e) => {
              e.target.src = '/DefaultBackgroundImage.png';
          }}
        />
        <img
          className='icon-img-container'
          src={fundDetails?.iconImage || '/DefaultLogo.png'}
          alt="Icon"
          onError={(e) => {
            e.target.src = '/DefaultLogo.png';
          }}
        />
        <div className='status-container'>
          <span className='status-text'>{fundDetails?.fundStatusName}</span>
        </div>
      </div>
      <div className='card-type space-between items-center margin-top-5'>
        <div className='card-details-header'>
          {fundDetails?.fundTypeName}
        </div>
        <div className='mp_fav_icon'>

          {favoritefunds?.find((item) => item?.fundID === fundDetails?.fundID)?
              <Tooltip title="Click to Remove">
            <FavoriteIcon 
              onClick={() => handleRemoveFavourite(fundDetails)}
              color="primary"
            /></Tooltip> :
            <Tooltip title="Add to Favorite">
            <FavoriteBorderIcon title="Favorite"
              onClick={() => handleAddFavourite(fundDetails)}
              sx={{ color: '#D2DAE0' }} /></Tooltip> 
          }


          {comparefunds?.find((item) => item?.fundID === fundDetails?.fundID) ?
            <Tooltip title="Click to Remove">
            <CompareArrowsIcon 
              onClick={() => handleRemoveCompare(fundDetails)}
              color="primary"
            /></Tooltip> :
            <Tooltip title="Add to Compare">
            <CompareArrowsIcon
              onClick={() => handleAddCompare(fundDetails)}
              sx={{ color: '#D2DAE0' }} /> </Tooltip>
          }

        </div>
      </div>
      <div className='card-header space-between margin-top-5'>
        <div>
          {fundDetails.fundName}
        </div>
      </div>
      <div className='card-desc margin-top-10'>
        {/*  {fundDetails.fundName} */}
        {fundDetails.fundDescription?.length > fundDescriptionMaxLength ? fundDetails.fundDescription?.slice(0, fundDescriptionMaxLength) + '...' : fundDetails.fundDescription}
      </div>
      <div className='card-details display-row space-around'>
        {/* {
          fundDetails?.fundTypeName === fundTypesEnum.PRIVATE_EQUITY &&
          <div className='card-details-header display-column'>
            <div>
              Target Return
            </div>
            <div className='card-details-value'>
              {fundDetails.targettedIRR}%
            </div>
          </div>
        } */}
        <div className='card-details-header display-column'>
          <div>
            Minimum
          </div>
          <div className='card-details-value'>
            ${fundDetails.minimumInvestmentAmount ? fundDetails.minimumInvestmentAmount.toLocaleString() : ''}
          </div>
        </div>
        {
          fundDetails?.fundTypeName === fundTypesEnum.REAL_ESTATE_FUND ? <></> :
          <div className='card-details-header display-column'>
            <div>
              Liquidity
            </div>
            <div className='card-details-value'>
              {fundDetails.liquidityProvision}
            </div>
          </div> 
        }
      </div>
      <div className='card-btns space-between'>
        <div>
          <Button
            component="label"
            variant="outlined"
            className="btn-primary"
            style={{ minWidth: '150px', minHeight: '37px' }}
            onClick={() => handleViewDetail(fundDetails, fundDetails.fundID, fundDetails.fundName, fundDetails.fundStatusName, allInvestors.some(u => u.fundID === fundDetails?.fundID))}
          >
            VIEW DETAIL
          </Button>
        </div>
        {
          allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.COMPLIANCE_REVIEW)
            || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.FM_REVIEW)
            || (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.APPROVED)
          ) ?
            <div>
              <Button
                component="label"
                variant="contained"
                onClick={onInvestmentFundClick}>
                MY INVESTMENT
              </Button>
            </div> :
            <div>
              <Button
                component="label"
                variant="contained"
                /* className="btn-primary" */
                style={
                  allInvestors.some(u => (u.fundID === fundDetails?.fundID && u.subscriptionStatus === fundSubscriptionStatues.INVESTOR_SIGN_PENDING))
                    ? { width: '150px', backgroundColor: '#F9C023', color: 'black', minHeight: '35px' } :
                    subscribebtn
                      ? { minWidth: '150px', minHeight: '35px' }
                      : { width: '150px', backgroundColor: '#F9C023', color: 'black', minHeight: '35px' }
                }


                onClick={() => {
                  handlesubscribe(fundDetails, fundDetails.fundID, fundDetails.fundName)
                }}
                disabled={subscribebtn}>
                {
                  renderSubscribeBtnLabel()
                }
              </Button>
            </div>
        }
      </div>
    </div>
  )
}

export default FundCardView