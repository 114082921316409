import React, { useState } from 'react'
import AppDiscussionComponent from '../../../common/AppDiscussion/AppDiscussionComponent'
import { LoadingButton } from '@mui/lab'
import ActionButton from '../../../common/ActionButton/ActionButton'
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { Grid } from '@mui/material';
import { AppDashboards } from '../../../../utils/enum';
import { formatAsUSACurrency } from '../../../common/CurrencyFormater/formatAsUSACurrency';
import secureLocalStorage from 'react-secure-storage';
import { Link, useNavigate } from 'react-router-dom';
import FundSubscriptionStatusBar from './FundSubscriptionStatusBar';
import { formatDate } from '../../../common/Functions/TimeConvestion';

const InvSubscriptionDetails = (props) => {
  //#region props
  const { fundDetails, fundId } = props;

  //#region variables
  const navigate = useNavigate();
  const [showComments, setShowsComments] = useState(false)
  const [appDiscussions, setAppDiscussions] = useState(null);

  //#region click events
  const onFundClick = () => {
    secureLocalStorage.setItem("FundId", fundId)
    navigate('/fund-details')
  }
  const onCommnetBtnClick = () => {
    setShowsComments(!showComments);
  }

  const postSubscriptionReview = [
    'Subscription Initiated',
    'Subscription Reviewed',
    'Subscription Approved',
    'Funds Received',
    'Investment Active',
    'Distributions Made',
    'Investment Matured',
    'Redemption Processed',
    'Investment Closed',
  ];

  //#region fields
  const investmentStatus = [
    {
      primaryLabel: 'Total Capital Committed',
      primaryValue: formatAsUSACurrency(fundDetails?.capitalCommitted !== null ? fundDetails?.capitalCommitted : 0),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Contributed Amount',
      primaryValue: formatAsUSACurrency(fundDetails?.contributedAmount),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Capital Remaining',
      primaryValue: formatAsUSACurrency(fundDetails?.capitalCommitted - fundDetails?.contributedAmount),
      secondaryLabel: '',
      secondaryValue: ''
    },
  ]
  const fundStatus = [
    {
      primaryLabel: 'Total Capital Committed',
      primaryValue: formatAsUSACurrency(fundDetails?.totalCapitalCallAmount !== null ? fundDetails?.totalCapitalCallAmount : 0),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Target Raise',
      primaryValue: formatAsUSACurrency(fundDetails?.fundSize !== null ? fundDetails?.fundSize : 0),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Total Remaining',
      primaryValue: formatAsUSACurrency(fundDetails?.fundSize - (fundDetails?.totalCapitalCallAmount)),
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Portfolio Companies',
      primaryValue: fundDetails?.numberofPortfolioCompanies ? fundDetails?.numberofPortfolioCompanies : 0,
      secondaryLabel: '',
      secondaryValue: ''
    },
    {
      primaryLabel: 'Current IRR',
      primaryValue: '18.5%',
      secondaryLabel: 'Target IRR',
      secondaryValue: `${fundDetails?.targettedIRR}%`
    },
  ]
  const fundDetailsFirstColumn = [
    {
      primaryLabel: "Vintage Year",
      primaryValue: fundDetails?.vintage,
    },
    {
      primaryLabel: "First Closing Date",
      primaryValue: fundDetails?.fundClosingDate == null ? '-' : formatDate(fundDetails?.fundClosingDate),
    },
    {
      primaryLabel: "Strategy",
      primaryValue: fundDetails?.strategyName,
    },
    {
      primaryLabel: "Geography",
      primaryValue: fundDetails?.geographicFocusName,
    },
    {
      primaryLabel: "Fund Name",
      primaryValue: <div className='font-color-blue underline cursor-pointer' onClick={onFundClick}>{fundDetails?.fundName}</div>,
    },
    {
      primaryLabel: "Application Date",
      primaryValue: fundDetails?.investorESignOn == null ? '-' : formatDate(fundDetails?.investorESignOn),
    },
  ]
  const fundDetailsSecondColumn = [
    {
      primaryLabel: "Sector",
      primaryValue: fundDetails?.targetIndustry,
    },
    {
      primaryLabel: "Currency",
      primaryValue: fundDetails?.fundCurrency ?? "USD",
    },
    // {
    //   primaryLabel: "General Partner",
    //   primaryValue:
    //     <div className="display-column ">
    //       {
    //         fundDetails?.isFirmAsGP ?
    //           <b>{secureLocalStorage.getItem("tenantName")}</b> :
    //           fundDetails?.fundStaffDetails?.map((item, index) => {
    //             return (
    //               <React.Fragment key={index}>
    //                 <b>
    //                   {item?.userName}
    //                 </b>
    //                 {index < fundDetails?.fundStaffDetails.length - 1 && ' , '}
    //               </React.Fragment>
    //             );
    //           })
    //       }
    //     </div>,
    // },
    {
      primaryLabel: "Fund Type",
      primaryValue: fundDetails?.fundTypeName,
    },
    {
      primaryLabel: "Fund Manager",
      primaryValue: fundDetails?.fundManager,
    },
    {
      primaryLabel: "Fund Manager Email ID",
      primaryValue: fundDetails?.fundManagerEmailAddress,
    },
  ]

  //#region return
  return (
    <div className='child-margin-15'>
      <div className='child-margin-15 white-card'>
        <div className='bubble-labelStyle-space'>
        <b>Investment Status</b>
        <span className='active-button'>{fundDetails?.subscriptionStatusName == "Rejected" ? "In-Active" : "Active"}</span>
        </div>
        <div className='progressbar-res'>
          <FundSubscriptionStatusBar statusName={fundDetails?.investmentStatusName} statusColor={fundDetails?.investmentStatusColor} />
        </div>
        <div className='child-margin-15'>
          <div className='child-margin-5'>
            <h6 className='font-color-blue'>My Fund Investment</h6>
            <div className='margin-top-minus-30'>
              {fundDetails &&
                <div className="Overview_statistics_row">
                  {investmentStatus?.map(stats => (
                    <div className="statisticsCard">
                      <div className='statisticsCard_title'>{stats.primaryLabel}</div>
                      <h4>{stats.primaryValue}</h4>
                      {
                        stats.secondaryLabel ? (
                          <div className="statisticsCard_b_title">
                            {/* <span>{stats.secondaryLabel} :  {fund.targettedIRR}%</span> */}
                          </div>
                        ) : (
                          <div style={{ visibility: 'hidden' }}>.</div>
                        )
                      }
                    </div>
                  ))}
                </div>
              }
            </div>
          </div>
          <div>
            <h6 className='font-color-blue mt2' >Total Fund</h6>
            <div className='margin-top-minus-20'>
              {fundDetails &&
                <div className="Overview_statistics_row">
                  {fundStatus?.map(stats => (
                    <div className="statisticsCard">
                      <div className='statisticsCard_title'>{stats.primaryLabel}</div>
                      <h4>{stats.primaryValue}</h4>
                      {
                        stats.secondaryLabel ? (
                          <div className="statisticsCard_b_title">
                            {/* <span>{stats.secondaryLabel} :  {fund.targettedIRR}%</span> */}
                          </div>
                        ) : (
                          <div style={{ visibility: 'hidden' }}>.</div>
                        )
                      }
                    </div>
                  ))}
                </div>
              }
            </div>
            <div>
              <div className="fundstatus_details">
                <div className="fundstatus_details_sec">
                  {
                    fundDetailsFirstColumn?.map((item) => {
                      return (
                        <div className="fundstatus_details_sec_row">
                          <label>{item.primaryLabel}</label>
                          <b>{item.primaryValue}</b>
                        </div>
                      )
                    })
                  }
                </div>
                <div className="fundstatus_details_sec">
                  {
                    fundDetailsSecondColumn?.map((item) => {
                      return (
                        <div className="fundstatus_details_sec_row">
                          <label>{item.primaryLabel}</label>
                          <b>{item.primaryValue}</b>
                        </div>
                      )
                    })
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div>
            <ActionButton
              variant='outlined'
              size="large"
              label={!showComments ? "SHOW COMMENTS" : "HIDE COMMENTS"}
              onClick={onCommnetBtnClick}
              icon={!showComments ? <VisibilityIcon /> : <VisibilityOffIcon />} />
          </div>
        </div>
      </div>
      {showComments &&
        <div className="white-card margin-top-20">
          <>
            <Grid item xs={12} spacing={2}>
              <h5>Notes and Comments</h5>
            </Grid>
            <Grid item xs={12} marginTop="20px">
              {/* <Editor/> */}
              <AppDiscussionComponent
                AppDiscussions={appDiscussions}
                setAppDiscussions={setAppDiscussions}
                subscriptionID={fundDetails?.subscriptionID}
                subscriptionStatus={postSubscriptionReview.includes(fundDetails.investmentStatusName) ? "Approved" : "Under Review" }
              />
            </Grid>
          </>
        </div>
      }
    </div>
  )
}

export default InvSubscriptionDetails