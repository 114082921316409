import React, { useEffect } from 'react'
import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import ActionButton from '../../../../../common/ActionButton/ActionButton';
import { useState } from 'react';
import TextInput from '../../../../../common/TextInput/TextInput';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';
import { Chip } from '@mui/material';
import TextField from '@mui/material/TextField';
import { CreateGroup } from '../../../../Services/GroupsServices';
import { ToastContainer, toast } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { updateGroupData } from '../../../../Services/GroupsServices';
import InvestorsListPopup from './InvestorsListPopup';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CancelIcon from '@mui/icons-material/Cancel';

const GroupsPopup = (props) => {
    
    //#region Props from Parent
    const { open, onClose ,data,isNewGroup,investorsList,refreshData,refresGroupDetails} = props;

    //#region Group Details
    const initialData = {
        groupID: data?.userGroupID || 0,
        groupName: data?.userGroupName || '',
        groupDescription: data?.userGroupDescription || '',
        groupMembers: data?.userGroupMembers ? 
                      investorsList?.filter((item) => 
                            data.userGroupMembers.some((i) => i.groupMemberUserID === item.userId)
                        ) || [] : []

    }

    //#region useState Variables
    const [groupDetails,setGroupDetails] = useState(initialData);
    const [errors, setErrors] = useState({});
    const [selectedInvestors, setSelectedInvestors] = useState(initialData?.groupMembers);
    const [loading,setLoading] = useState(false);

    //#region Popup Variables
    const [investorsPopup,setInvestorsPopup] = useState(false);

    //#region events
    const handleChange = (name, value) => {
        setGroupDetails(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const onAutoCompleteChange = (e, value) => {
        const latestSelection = value.map(u => u.userId);
        const newItems = investorsList.filter(u => latestSelection?.includes(u.userId));
        setSelectedInvestors(investorsList.filter(u => latestSelection?.includes(u.userId)));
    }

    const handleOpenInvestorsList = () => {
        setInvestorsPopup(true);
    }
    const handleCloseInvestorsList = () => {
        setInvestorsPopup(false);
        setSelectedInvestors([])
    }

    //#region Post API Calls
    const handleSubmit = async() => {
        setLoading(true);
        const investorsData = selectedInvestors.map((investor) => ({
            groupMemberID: 0, 
            userGroupID: 0, 
            groupMemberUserID: investor.userId,
            groupMemberName: investor.userFullName, 
            groupMemberEmail: "", 
            isDeleted: false, 
            createdBy: secureLocalStorage.getItem("userId").toString(),
            createdDate: "2024-01-30T12:12:43.732Z", 
            updatedBy: secureLocalStorage.getItem("userId").toString(), 
            updatedDate: "2024-01-30T12:12:43.732Z", 
          }));

        const CreateGroupRequestData = {
            "userGroupID": groupDetails?.groupID,
            "userGroupName": groupDetails.groupName,
            "userGroupDescription": groupDetails.groupDescription,
            "tenantGUID" : secureLocalStorage.getItem('tenantID'),
            "isActive": true,
            "isDeleted": false, 
            "noOfGroupMembers" : 0,
            "createdBy": secureLocalStorage.getItem("userId"),
            "createdDate": "2024-01-30T12:12:43.732Z",
            "updatedBy": secureLocalStorage.getItem("userId"),
            "updatedDate": "2024-01-30T12:12:43.732Z",
            "userGroupMembers": investorsData
          };

        let data = null;
        if(isNewGroup){
            data = await CreateGroup(CreateGroupRequestData);
        }
        else{
            data = await updateGroupData(CreateGroupRequestData);
        }
        if (data?.responseCode === 200) {
            toast.success(`Group ${isNewGroup ? ' Created' : ' Updated'} Sucessfully`,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            setLoading(false);
            onClose();
            refreshData();
            if(!isNewGroup){
                refresGroupDetails();
            }
        }
        else {
            toast.warning(`Unable to ${isNewGroup ? 'Create' : 'Update'} the Group`,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
            setLoading(false);
            onClose();
        }
    }

    return (
        <Dialog open={open} fullWidth>
            <DialogTitle>
                 { isNewGroup ? 'Add a Group' : 'Edit Group' }
            </DialogTitle>
            <DialogContent>
               <div className="margin-top-15 margin-bottom-35">
                    <TextInput
                        type='text'
                        name="groupName"
                        required={true}
                        defaultValue={groupDetails.groupName}
                        label="Name of Group"
                        onChange = {(name,value)=>{handleChange(name,value)}}
                        errormessage={errors.groupName || undefined} />
                </div>
                <div className="margin-top-15">
                    <TextInput
                        type='text'
                        name="groupDescription"
                        placeholder="Brief Description about the Group"
                        required={true}
                        multiline
                        rows={3}
                        defaultValue={groupDetails.groupDescription}
                        onChange = {(name,value)=>{handleChange(name,value)}}
                        errormessage={errors.groupDescription || undefined} />
                </div>
                <div className="margin-top-15">
                <Grid item container xs={12}>
                    <Grid item xs={11}>
                        <div>
                            <Autocomplete
                                multiple
                                id="emailTo"
                                size="small"
                                sx={{ width: "100%"}}
                                options={investorsList || []}
                                getOptionLabel={(option) => option?.userEmailAddress}
                                value={selectedInvestors}
                                onChange={onAutoCompleteChange}
                                renderTags={(value, getTagProps) =>
                                    value.map((option, index) => (
                                        <Chip
                                            variant="outlined"
                                            label={option?.userFullName}
                                            size="small"
                                            {...getTagProps({ index })}

                                        />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="filled"
                                        label="Members of Group"
                                        placeholder="Members of Group"
                                        sx={{
                                            color: 'blue', // Set the color for the link
                                            cursor: 'pointer',
                                            textDecoration: 'underline',
                                        }}
                                    //onClick={handleLinkClick}
                                    />
                                )}
                            />
                        </div>
                    </Grid>
                    <Grid item xs={1} marginTop={0.5}>
                        <div className="IC-searchbtn">
                            <SearchIcon sx={{
                            }} onClick={handleOpenInvestorsList} fontSize='large' />
                        </div>
                    </Grid>
                </Grid>
                </div>
            </DialogContent>
            <DialogActions>
                <div className="space-between">
                    <div>
                       <ActionButton variant="outlined" onClick={onClose} 
                       icon={ <CancelIcon/>}
                       label="Cancel" />
                    </div>
                    <div className="margin-left-10">
                        <ActionButton
                        icon={<TouchAppIcon/>}
                            label={isNewGroup ? "Submit" : "Save"}
                            loading={loading}
                            disabled={groupDetails.groupName === '' || groupDetails.groupDescription === '' || selectedInvestors.length === 0}
                            onClick={handleSubmit} />
                    </div>
                </div>
            </DialogActions>
            {
                investorsPopup && <InvestorsListPopup 
                                      open={investorsPopup} 
                                      onClose={handleCloseInvestorsList} 
                                      investorsList={investorsList}
                                      selectedInvestors={selectedInvestors}
                                      setSelectedInvestors={setSelectedInvestors} 
                                      isGroupsPopup = {true}
                                      />
            }
        </Dialog>
    )
}

export default GroupsPopup