import Indicator from "../common/DataGrid/Indicator";
import CurrencyIndicator from "../common/DataGrid/CurrencyIndicator";
import { IconButton, Tooltip } from "@mui/material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { stringOperators, numericOperators, dropdownOperators, subscriptionStatusOperator,ApplicationDateOperators, VisibilitydropdownOperators } from "./myfundFilterOperators";
import { formatDate } from "../common/Functions/TimeConvestion";

export const fundTypeDropdownOptions = [
    { value: 'Private Credit', label: 'Private Credit' },
    { value: 'Private Equity', label: 'Private Equity' },
];

export const coFundTypeDropdownOptions = [
    { value: 'Private Credit Fund', label: 'Private Credit' },
    { value: 'Private Equity Fund', label: 'Private Equity' },
];

export const fundTypeDropdown = [
    { value: 1, label: 'Private Credit' },
    { value: 2, label: 'Private Equity' },
]

export const subscriberStatusEnum = {
    ALL: 'all',
    ACTIVE: 'active',
    IN_ACTIVE: 'Rejected'
}

export const subscriberStatusDropdown = [
    { label: "All", value: subscriberStatusEnum.ALL },
    { label: "Active", value: subscriberStatusEnum.ACTIVE },
    { label: "In-Active", value: subscriberStatusEnum.IN_ACTIVE },
]

export const subscribersDateOptions = [
    {label : "All",value: "All"},
    {label : "Recent",value: "Recent"},
    {label : "Last 7 Days",value: "Last7Days"},
    {label : "Last week",value: "Last1Week"},
    {label : "Last 30 Days",value: "Last1Month"},
    {label : "Last month",value: "LastMonth"}
]

export const allColumns = [
    {
        id: 1,
        field: "fundName",
        headerName: "Fund Name",
        width: 250,
        filterOperators: stringOperators,
    },
    {
        id: 2,
        field: "fundStatusName",
        headerName: "Status",
        width: 220,
        filterOperators: dropdownOperators,
    },
    {
        id: 3,
        field: "capitalCommitted",
        headerName: "Capital Committed",
        width: 200,
        renderCell: (params) => {
            return <CurrencyIndicator params={params} />;
        },
        filterOperators: numericOperators,
    },
    {
        id: 4,
        field: "aum",
        headerName: "AUM",
        width: 100,
        renderCell: (params) => {
            return <CurrencyIndicator params={params} />;
        },
        filterOperators: numericOperators,
    },
    {
        id: 5,
        field: "noOfInvestors",
        headerName: "# of Investors",
        width: 150,
        filterOperators: numericOperators,
        renderCell: (params) => {
            return  params.row.noOfInvestors ?? 0;
        },
    },
    {
        id: 6,
        field: "notificationsCount",
        headerName: "# of Notifications",
        width: 150,
        filterOperators: numericOperators,
        renderCell: (params) => {
            return  params.row.notificationsCount ?? 0;
        },
    },
    {
        id: 7,
        field: "visibility",
        headerName: "Visibility",
        width: 100,
        renderCell: (params) => {
            return (
                params.row.visibility === "1" ? (
                    <Tooltip title={'View Detail'} arrow>
                      <IconButton className="micons"><VisibilityIcon /></IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={'Hide Fund'} arrow>
                      <IconButton className="micons"><VisibilityOffIcon /></IconButton>
                    </Tooltip>
                  )
            ) ;
        },
        filterOperators: VisibilitydropdownOperators,
    },
];

// Columns for Private Equity
export const privateEquityColumns = [
    allColumns[0], // Fund Name
    allColumns[1], // Status
    allColumns[2], // Capital Committed
    allColumns[3], // AUM
    allColumns[4], // # of Investors
    allColumns[5], // Notifications
    allColumns[6], // Visibility
];

// Columns for Private Credit
export const privateCreditColumns = [
    allColumns[0], // Fund Name
    allColumns[1], // Status
    allColumns[3], // AUM
    allColumns[5], // Notifications
    allColumns[6], // Visibility
];

// Columns for Private Credit
export const ventureCapitalColumn = [
    allColumns[0], // Fund Name
    allColumns[1], // Status
    allColumns[3], // AUM
    allColumns[5], // Notifications
    allColumns[6], // Visibility
];


export const newSubscribersColumns = [
    {
        id: 1,
        field: "InvestorName",
        headerName: "Investor Name",
        width: 130,
        valueGetter: (params) => `${params.row.firstName} ${params.row.lastName}`,
        filterOperators: stringOperators
    },
    {
        id: 3,
        field: "type",
        headerName: "Investor Type",
        width: 130,
        filterOperators: stringOperators
    },
    {
        id: 2,
        field: "fundName",
        headerName: "Fund Name",
        width: 150,
        filterOperators: stringOperators
    },
    {
        id: 4,
        field: "subscriptionStatusName",
        headerName: "Subscription Status",
        width: 220,
        filterOperators: subscriptionStatusOperator,
        renderCell: (params) => {
            return <Indicator params={params} />
        }
    },
    {
        id: 5,
        field: "capitalCommitted",
        headerName: "Capital Committed",
        width: 145,
        renderCell: (params) => {
            return <CurrencyIndicator params={params} />
        }
    },
    {
        id: 6,
        field: "investorESignOn",
        headerName: "Application Date",
        width: 145,
        valueGetter: (params) => params.value ? formatDate(params?.value) : null
    }
];


// Columns for Private Equity
export const newSubscribersprivateCreditColumns = [
    newSubscribersColumns[0],
    newSubscribersColumns[1],
    newSubscribersColumns[2],
    newSubscribersColumns[3],
    newSubscribersColumns[5],
];

// Columns for Private Credit
export const newSubscribersprivateEquityColumns = [
    newSubscribersColumns[0], // Fund Name
    newSubscribersColumns[1], // Status
    newSubscribersColumns[2], // AUM
    newSubscribersColumns[3], // Notifications
    newSubscribersColumns[4], // Visibility
    newSubscribersColumns[5],
];
