import React from "react";
import { Box, height } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { getrootBackgroundColorConfig } from "../../../state/slices/colorConfigSlice";
import { useSelector } from "react-redux";
import secureLocalStorage from "react-secure-storage";
import { useState } from "react";
import StackView from "../../common/StackView/StackView";
import { CurrencyFormatter } from "../../../utils/Formater";
import { useEffect } from "react";
import { GetFundSubscriptions } from "../Services/Services";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import IconButton from '@mui/material/IconButton';
import { HeaderTemplates, PageHeader } from "../../common/PageHeader/PageHeader";
import FundSubscriptionDetails from "./FundSubscriptionReview";
import SelectField from "../../common/input-fields/SelectField";
import { FieldVariants } from "../../common/TextInput/appInputenum";
import { coFundTypeDropdownOptions } from "../../FundManagerDashboard/fundTypeValues";
import CircleIcon from "../../chatbot/Icon";
import { getKeyValuePairs } from "../../portfolio-companies/services/services";
import { fundTypesEnum } from "../../marketplace-dashboard/jsonData";

//#this component is Compliance Officer Dashbaord
const CODashboard = () => {

        //#region intialdata
        const intialData = {
                "pendingInvestors": "All",
                "approvedInvestors": "All",
                "rejectedInvestors": "All"
        }

        //#region variables
        const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
        const [subscriptionsList, setSubscriptionsList] = useState(null);
        const [showLists, setShowLists] = useState(true);
        const [selectedSubscription, setSelectedSubscription] = useState(null);
        const [filters, setFilters] = useState(intialData)
        const [pendingInvestors, setPendingInvestors] = useState([]);
        const [approvedInvestors, setApprovedInvestors] = useState([]);
        const [rejectedInvestors, setRejectedInvestors] = useState([]);
        const [fundTypes, setFundTypes] = useState([]);

        //#region api get call
        const getFundTypes = async () => {
                const data = await getKeyValuePairs("FundType");
                if (data.responseCode === 200) {
                        const dropdownValues = data.responseData?.map(option => ({ label: option.listItemValue, value: option.listItemValue })) || []
                        setFundTypes(dropdownValues)
                }
                else {
                        setFundTypes([])
                }
        }

        const ApprovedColumns = [
                {
                        "id": 1,
                        "field": "investor",
                        "headerName": "Investor Name",
                        "width": 200,
                        renderCell: (params) => {
                                <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{
                                                width: 28,
                                                height: 28,
                                                borderRadius: "50%",
                                                background: "lightgray",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginRight: 8,
                                        }}>
                                                <img
                                                        src="/icons/icon-awesomebuilding.svg"
                                                        alt="Image"
                                                        style={{ width: 12 }}
                                                />
                                        </div>
                                        {params.value}
                                </div>
                        }
                },
                {
                        "id": 2,
                        "field": "investorType",
                        "headerName": "Investor Type",
                        "width": 200
                },
                {
                        "id": 3,
                        "field": "fundName",
                        "headerName": "FundName",
                        "width": 180
                },
                {
                        "id": 4,
                        "field": "fundTypeName",
                        "headerName": "Fund Type",
                        "width": 200
                },
                {
                        "id": 5,
                        "field": "subscribed",
                        "headerName": "Capital Commited Amount",
                        "width": 200
                },
                {
                        "id": 6,
                        "field": "subscriptionStatus",
                        "headerName": "Subscription Status",
                        "width": 200
                },
                // {
                //         "id": 7,
                //         "field": "subscriptionType",
                //         "headerName": "Subscription Type",
                //         // "width": 180
                // }
        ]

        const SubscriptionColumns = [
                {
                        "id": 1,
                        "field": "investor",
                        "headerName": "Investor Name",
                        "width": 200,
                        renderCell: (params) => {
                                <div style={{ display: "flex", alignItems: "center" }}>
                                        <div style={{
                                                width: 28,
                                                height: 28,
                                                borderRadius: "50%",
                                                background: "lightgray",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                marginRight: 8,
                                        }}>
                                                <img
                                                        src="/icons/icon-awesomebuilding.svg"
                                                        alt="Image"
                                                        style={{ width: 12 }}
                                                />
                                        </div>
                                        {params.value}
                                </div>
                        }
                },
                {
                        "id": 2,
                        "field": "investorType",
                        "headerName": "Investor Type",
                        "width": 200
                },
                {
                        "id": 3,
                        "field": "fundName",
                        "headerName": "FundName",
                        "width": 180
                },
                {
                        "id": 4,
                        "field": "fundTypeName",
                        "headerName": "Fund Type",
                        "width": 200
                },
                {
                        "id": 5,
                        "field": "subscribed",
                        "headerName": "Amount",
                        // "width": 150
                },
                {
                        "id": 6,
                        "field": "subscriptionStatus",
                        "headerName": "Subscription Status",
                        "width": 200
                },
                {
                        "id": 7,
                        "field": "subscriptionType",
                        "headerName": "Subscription Type",
                        // "width": 180
                }
        ]

        //#region change events
        const handleChange = (field, value) => {
                const currState = { ...filters };
                currState[field] = value;
                setFilters(currState)
        }

        //#region click events
        const onGridItemClick = (params) => {
                setSelectedSubscription(params.row);
                setShowLists(false);
        }

        const onBackButtonClick = () => {
                setSelectedSubscription(null);
                setShowLists(true);
                GetFundSubscriptionsAsync();
        }

        //#region api get calls
        const GetFundSubscriptionsAsync = async () => {
                try {
                        const apiResponse = await GetFundSubscriptions(0);

                        if (apiResponse.responseCode === 200) {
                                const allSubscriptions = apiResponse.responseData
                                        ? apiResponse.responseData
                                                .map((u) => ({
                                                        id: u.subscriptionsID,
                                                        investor: u.investor,
                                                        investorType: u.userInvestorType,
                                                        fundName: u.fundName,
                                                        subscribed: CurrencyFormatter(u.subscribed),
                                                        subscriptionStatus: (u.subscriptionStatus === 'Under Compliance Review' ? 'Under Review' : u.subscriptionStatus),
                                                        subscriptionID: u.subscriptionsID,
                                                        fundID: u.fundID,
                                                        investorID: u.investorID,
                                                        isOfflineSubscription: u.isOfflineSubscription,
                                                        subscriptionType: u.isOfflineSubscription ? "Offline" : "Online",
                                                        offlineFile: u.offlineFile,
                                                        offlineFilePath: u.offlineFilePath,
                                                        fundLogo: u.fundLogo,
                                                        userProfileIcon: u.userProfileIcon,
                                                        fundTypeName: u.fundTypeName,
                                                        isInvestorVerified: u.isInvestorVerified
                                                }))
                                        : [];

                                console.clear();
                                console.log('my response: ', allSubscriptions);

                                setSubscriptionsList(allSubscriptions);

                        } else {
                                console.log('error happened');
                        }
                }
                catch (error) {
                        console.error('An error occurred:', error);
                }
        };

        //#region useeffect
        useEffect(() => {
                GetFundSubscriptionsAsync();
        }, []);

        useEffect(() => {
                if (filters.pendingInvestors !== "") {
                        const investors = subscriptionsList?.filter((u) => u.subscriptionStatus === "Under Review" || u.subscriptionStatus === 'Under Fund Manager Review'
                        ) || []
                        //     debugger
                        setPendingInvestors((filters.pendingInvestors === "All") ? (investors) :
                                (investors?.filter((inv) => inv?.fundTypeName === filters.pendingInvestors)))
                }
                if (filters.approvedInvestors !== "") {
                        const investors = subscriptionsList?.filter((u) => u.subscriptionStatus === "Approved") || []
                        setApprovedInvestors(filters.approvedInvestors === "All" ? (investors) :
                                investors?.filter((inv) => inv?.fundTypeName === filters.approvedInvestors))
                }
                if (filters.rejectedInvestors !== "") {
                        //   debugger
                        const investors = subscriptionsList?.filter((u) => u.subscriptionStatus === "Rejected" || u.subscriptionStatus === "Cancelled") || []
                        const rejectedInvestors = (filters.rejectedInvestors === "All") ? (investors) :
                                investors?.filter((inv) => inv?.fundTypeName === filters.rejectedInvestors)
                        setRejectedInvestors(rejectedInvestors)
                }
        }, [filters, subscriptionsList])

        useEffect(() => {
                getFundTypes()
        }, [])

        console.log('fundtypes', fundTypes)
        //#region return
        return (
                <div style={{ backgroundColor: rootBackColorConfig.color }}>
                        {showLists ?
                                <Box sx={{ flexGrow: 1 }}>
                                        <Grid container xs={12} md={12} lg={12} spacing={1}>
                                                <div className="wrapper full-width">
                                                        <Grid item container xs={12} marginTop={2} sx={{ verticalAlign: 'center' }} >
                                                                {!showLists &&
                                                                        // <div onClick={onBackButtonClick}>
                                                                        //         <ArrowBackIcon sx={{ height: '24px', width: '24px', marginRight: '5px', marginTop: '-3px' }} />
                                                                        // </div>
                                                                        <IconButton onClick={onBackButtonClick} title="Click to Back" >
                                                                                <KeyboardBackspaceOutlinedIcon className='backIcon' fontSize='large' />
                                                                        </IconButton>
                                                                }
                                                                {showLists &&
                                                                        <div className="font-size-xsmall font-size-color welcomeuser" style={{ marginTop: '12px' }}>
                                                                                Welcome Back, {secureLocalStorage.getItem("fullName")}
                                                                        </div>}

                                                        </Grid>
                                                        <div>{showLists ?
                                                                <h1>Compliance Officer Dashboard</h1> :
                                                                <>

                                                                </>}</div>
                                                        <Grid item container xs={12} md={12} lg={12} className="whiteCard">
                                                                <Grid item xs={12} sm={12} md={12} lg={12}>
                                                                        <div className="space-between">
                                                                                <h6>Investor List</h6>
                                                                                <div className='dash-select'>
                                                                                        <SelectField
                                                                                                className="fundtype-select"
                                                                                                name='pendingInvestors'
                                                                                                label="Fund Type"
                                                                                                size='small'
                                                                                                fullWidth
                                                                                                variant={FieldVariants?.OUTLINED}
                                                                                                value={filters?.pendingInvestors}
                                                                                                options={fundTypes ?? fundTypes}
                                                                                                onChange={(name, value) => handleChange(name, value)} />
                                                                                </div>
                                                                        </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                        <div>
                                                                                <StackView
                                                                                        key={pendingInvestors.length}
                                                                                        columns=
                                                                                        {SubscriptionColumns}
                                                                                        rows={pendingInvestors}
                                                                                        handleRowClick={onGridItemClick}
                                                                                        // handleRowClick={onCustomerClickstatus}
                                                                                        onRowsSelectionHandler={() => { }}
                                                                                        sx={{
                                                                                                "& .MuiDataGrid-row:hover": {
                                                                                                     cursor: "pointer" // Clickable row with hand symbol
                                                                                                    },
                                                                                                 }}
                                                                                />
                                                                        </div>
                                                                </Grid>
                                                        </Grid>
                                                        <Grid item container xs={12} marginTop={2} className="whiteCard">
                                                                <Grid item xs={12} >
                                                                        <div className="space-between">
                                                                                <h6>Approved</h6>
                                                                                <div className='dash-select'>
                                                                                        <SelectField
                                                                                                className="fundtype-select"
                                                                                                name='approvedInvestors'
                                                                                                label="Fund Type"
                                                                                                size='small'
                                                                                                fullWidth
                                                                                                variant={FieldVariants?.OUTLINED}
                                                                                                value={filters?.approvedInvestors}
                                                                                                options={fundTypes ?? fundTypes}
                                                                                                onChange={(name, value) => handleChange(name, value)} />
                                                                                </div>
                                                                        </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                        <div>
                                                                                <StackView
                                                                                        columns={ApprovedColumns}
                                                                                        rows={approvedInvestors}
                                                                                        key={approvedInvestors.length}
                                                                                        handleRowClick={onGridItemClick}
                                                                                // onRowsSelectionHandler={() => { }}
                                                                                        sx={{
                                                                                         "& .MuiDataGrid-row:hover": {
                                                                                            cursor: "pointer" // Clickable row with hand symbol
                                                                                        },
                                                                                        }}
                                                                                />
                                                                        </div>
                                                                </Grid>
                                                        </Grid>
                                                        <Grid item container xs={12} marginTop={2} className="whiteCard">
                                                                <Grid item xs={12}>
                                                                        <div className="space-between">
                                                                                <h6>Rejected</h6>
                                                                                <div className='dash-select'>
                                                                                        <SelectField
                                                                                                className="fundtype-select"
                                                                                                name='rejectedInvestors'
                                                                                                label="Fund Type"
                                                                                                size='small'
                                                                                                fullWidth
                                                                                                variant={FieldVariants?.OUTLINED}
                                                                                                value={filters?.rejectedInvestors}
                                                                                                options={fundTypes ?? fundTypes}
                                                                                                onChange={(name, value) => handleChange(name, value)} />
                                                                                </div>
                                                                        </div>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                        <div>
                                                                                <StackView
                                                                                        columns={ApprovedColumns}
                                                                                        rows={rejectedInvestors}
                                                                                        key={rejectedInvestors.length}
                                                                                // handleRowClick={onCustomerClickstatus}
                                                                                // onRowsSelectionHandler={() => { }}
                                                                                        sx={{
                                                                                        "& .MuiDataGrid-row:hover": {
                                                                                          cursor: "pointer" // Clickable row with hand symbol
                                                                                          },
                                                                                         }}
                                                                                />
                                                                        </div>
                                                                </Grid>
                                                        </Grid>
                                                        <Grid item container xs={12} color={"whilte"} marginTop={"100px"} >

                                                        </Grid>
                                                </div>
                                        </Grid>
                                </Box>
                                :
                                <>
                                        <PageHeader
                                                title="Subscription Details"
                                                template={HeaderTemplates.NORMAL_VIEW}
                                                isNavigate={() => { setShowLists(true) }} />
                                        <div className="wrapper">
                                                <FundSubscriptionDetails subscriptionID={selectedSubscription?.subscriptionID} subscriptionDetails={selectedSubscription} />
                                        </div>
                                </>
                        }
                        <CircleIcon />
                </div>
        )
}

export default CODashboard;