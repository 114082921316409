import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { DataGrid , GridColumnMenu} from '@mui/x-data-grid';
import { Box } from "@mui/material";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { ArrowDropDown , ArrowDropUp} from "@mui/icons-material"
import './Stack.css'; // Path to your custom CSS file

const FundGridView = (props)  => {

  //#region props
  const { columns, rows,pagination, handleRowClick, onRowsSelectionHandler,norowsMessage,isHeight=true} = props;  


  return (
      <DataGridPro
          rows={rows}
          key={rows?.length}
          columns={columns}
          rowHeight={100}
          disableColumnMenu={false}
          onRowClick={handleRowClick}
          classes={{
            row: 'custom-row', 
            cell: 'custom-cell',
          }}
          slots={{
            columnSortedDescendingIcon: ArrowDropDown,
            columnSortedAscendingIcon: ArrowDropUp,
            columnMenu: CustomColumnMenu,
          }}
          localeText={{ noRowsLabel: norowsMessage || "no rows" }}
          getRowClassName={(params) => {
            return (params.row.fundTerminated || params.row.isFundMatured) ? "gray-out" : "";
          }}
          sx={{
            ".gray-out": {
              color: "#ff0000",
            },
            "& .MuiDataGrid-row:hover": {
                  cursor: "pointer" // Clickable row with hand symbol
            },
          }}
          pagination={pagination}
          paginationModel={{ pageSize: 10 }}
          pageSizeOptions={[10,20,50]} />
  )
}

const CustomColumnMenu = (props) => {
    return (
      <GridColumnMenu 
      sx={{   marginTop: '30px' }}
        {...props}
        slots={{
          columnMenuColumnsItem: null,
          columnMenuSortItem: null,
        }}
      />
    );
  };

export default FundGridView