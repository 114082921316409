import * as React from 'react';
import { useEffect } from 'react';
import { DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import ActionButton from '../common/ActionButton/ActionButton';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Accordian from '../common/Accordian/Accordian';
import { InputField, WhileLabelingInputField } from './InputField';
import { LabelingConfig } from './Constants';
import { useDispatch, useSelector } from 'react-redux';
import { getColorConfig, updateColorConfig } from '../../state/slices/colorConfigSlice';
import secureLocalStorage from 'react-secure-storage';
import Configurations from '../../Configurations';
import TouchAppIcon from '@mui/icons-material/TouchApp';
import { ResetWhiteLabelingConfig } from './Constants';
import { ToastContainer, toast } from 'react-toastify';
import WhiteLabelingConfigConfirmation from './WhiteLabelingConfigConfirmation';
import ResetConfirmationPopup from './ResetConfirmationPopup';
import WhitelabelingAccordian from './WhitelabelingAcccordian';

export const WhiteLabeling = (props) => {
  let DefaultLabelingConfig = useSelector(getColorConfig);
  const [currConfig, setConfig] = React.useState(DefaultLabelingConfig);
  const [confirmation, setConfirmation] = React.useState(false);
  const [resetConfirmation, setResetConfirmation] = React.useState(false);
  const dispatch = useDispatch();

  const handleUpdateConfig = (root, name, value) => {
    let resConfig = { ...currConfig[root] };
    let actualConfig = { ...currConfig };
    resConfig[name] = value;
    actualConfig[root] = resConfig;
    setConfig({ ...currConfig, ...actualConfig });
  };

  const onApplyClick = () => {
    setConfirmation(true);
  };

  const onConfirmationPopupClose = () => {
    setConfirmation(false);
  };

  const onOpenResetPopup = () => {
    setResetConfirmation(true);
  };

  const onCloseResetPopup = () => {
    setResetConfirmation(false);
  };

  const handleApplyConfig = () => {
    let resConfig = currConfig;
    const updateWhiteLabellingConfig = async () => {
      fetch(`${Configurations.apiBaseUri}/v1/UserRole/SaveTenantCustomizations`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          whiteLabellingID: 0,
          tenantGuid: secureLocalStorage.getItem('tenantID'),
          ...resConfig,
        }),
      })
        .then((response) => {
          if (response.ok) {
            response.json().then((data) => {
              setConfirmation(false);
              console.log('labelling data' + data);
              dispatch(updateColorConfig(data));
              window.location.reload(true); // Reload the window from the server and force cache refresh
            });
          } else {
            console.error(response);
            throw new Error('Failed to fetch Data');
          }
        });
    };
    updateWhiteLabellingConfig();
  };

  const handlePreviewConfig = () => {
    handleApplyConfig();
  };

  const ResetConfig = async () => {
    const data = await ResetWhiteLabelingConfig(secureLocalStorage.getItem('tenantID'));
    if (data?.responseCode === 200) {
      dispatch(updateColorConfig(data?.responseData));
      toast.success('Your configuration has been reset successfully. Please perform a hard refresh to apply the changes.', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
      window.location.reload(true);
    } else {
      toast.warning('Something went wrong,please try again', {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: 'colored',
      });
    }
  };

  return (
    <div>
      <h4>White Labeling</h4>
      <div className="white-card">
        <div>
          {LabelingConfig.map((config) => (
            <div className="accordianWrapper" key={config.title}>
              <WhitelabelingAccordian
                title={config.title}
                defaultExpanded
                body={
                  <>
                    <Typography sx={{ color: 'text.primary' }}>{config.subText}</Typography>
                    {config.fields.map((field) => (
                      <WhileLabelingInputField
                        key={field.name}
                        value={currConfig[config.name][field.name]}
                        onChange={(val) => handleUpdateConfig(config.name, field.name, val)}
                        {...field}
                      />
                    ))}
                    {/* Buttons for each section */}
                    <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
                      {config.buttons.map((button, index) => (
                        <ActionButton
                          key={index}
                          variant={button.type === 'reset' ? 'outlined' : 'contained'}
                          icon={button.type === 'reset' ? <RotateLeftIcon /> : <TouchAppIcon />}
                          label={button.type === 'reset' ? 'Reset' : 'Apply'}
                          onClick={button.type === 'reset' ? onOpenResetPopup : onApplyClick}
                        />
                      ))}
                    </div>
                  </>
                }
              />
            </div>
          ))}
        </div>
        {/* Global Reset and Apply Buttons */}
        {/* <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginTop: '20px' }}>
          <ActionButton
            variant="outlined"
            icon={<RotateLeftIcon />}
            label="Reset"
            onClick={() => onOpenResetPopup()}
          />
          <ActionButton icon={<TouchAppIcon />} label="Apply" onClick={() => onApplyClick()} />
        </div> */}
      </div>
      <ToastContainer />
      {confirmation && (
        <WhiteLabelingConfigConfirmation
          confirmation={confirmation}
          handleApplyConfig={handleApplyConfig}
          onClose={onConfirmationPopupClose}
        />
      )}
      {resetConfirmation && (
        <ResetConfirmationPopup
          confirmation={resetConfirmation}
          handleApplyConfig={ResetConfig}
          onClose={onCloseResetPopup}
        />
      )}
    </div>
  );
};
