import React, { useState, useEffect } from 'react';
import { getFundsSubscribers } from '../Services/FundNewsService';
import { fundTypesEnum } from '../../marketplace-dashboard/jsonData';
import { newSubscribersColumns, newSubscribersprivateCreditColumns, newSubscribersprivateEquityColumns, subscriberStatusEnum, subscriberStatusDropdown, subscribersDateOptions } from '../fundTypeValues';
import { checkFeaturePermissions } from '../../../utils/common';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import secureLocalStorage from 'react-secure-storage';
import { useNavigate } from 'react-router-dom';
import SelectField from '../../common/input-fields/SelectField';
import { FieldVariants } from '../../common/TextInput/appInputenum';
import { AppDashboards, RolesAndPermissions, FeatureAccess } from '../../../utils/enum';

const NewSubscribersGrid = (props) => {
  //#region props
  const { fundTypes } = props;

  //#region intialdata
  const initialData = {
    fundStatus: subscriberStatusEnum.ALL,
    fundType: fundTypesEnum.ALL,
  };

  //#region vairables
  const navigate = useNavigate();
  const [subscribersColumns, setSubscribersColumns] = useState(newSubscribersColumns);
  const [subscribersData, setSubscribersData] = useState([]);
  const [filteredSubscribers, setFilteredSubscribers] = useState([]);
  const [filters, setFilters] = useState(initialData);
  const [selectedPeriod, setSelectedPeriod] = useState('All');
  const [paginationModel, setPaginationModel] = useState({ pageSize: 10, page: 0 });

  //#region change events
  const handleFilterChange = (name, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));

    let filteredData = subscribersData;

    if (name === 'fundType') {
      const selectedFundType = fundTypes.find(u => u.value === value).label;
      if (selectedFundType === fundTypesEnum.PRIVATE_EQUITY) {
        setSubscribersColumns(newSubscribersprivateEquityColumns);
        filteredData = filteredData.filter((fund) => fund.fundTypeName === fundTypesEnum.PRIVATE_EQUITY);
      } else if (selectedFundType === fundTypesEnum.PRIVATE_CREDIT) {
        setSubscribersColumns(newSubscribersprivateCreditColumns);
        filteredData = filteredData.filter((fund) => fund.fundTypeName === fundTypesEnum.PRIVATE_CREDIT);
      } else if (selectedFundType === fundTypesEnum.VENTURE_CAPITAL) {
        setSubscribersColumns(newSubscribersprivateEquityColumns);
        filteredData = filteredData.filter((fund) => fund.fundTypeName === fundTypesEnum.VENTURE_CAPITAL);
      } else if (selectedFundType === fundTypesEnum.REAL_ESTATE_FUND) {
        setSubscribersColumns(newSubscribersprivateEquityColumns);
        filteredData = filteredData.filter((fund) => fund.fundTypeName === fundTypesEnum.REAL_ESTATE_FUND);
      } else {
        setSubscribersColumns(newSubscribersColumns);
      }
    }

    if (name === 'fundStatus' && value === subscriberStatusEnum.IN_ACTIVE) {
      filteredData = [];
    }

    setFilteredSubscribers(filteredData);
  };

  //#region click events
  const subscriberClick = (params) => {
    secureLocalStorage.setItem('subscriberID', params.row.investorID);
    if (checkFeaturePermissions(RolesAndPermissions.INVESTOR_PROFILE, FeatureAccess.READ)) {
      secureLocalStorage.setItem('InvestorName', `${params.row.firstName} ${params.row.lastName}`);
      secureLocalStorage.setItem('subscriberID', params.row.investorID);
      secureLocalStorage.setItem('InvestorprofilePic', params.row.investorProfileIcon);
      navigate('/subscriber-profile');
      window.scrollTo(0, 0);
    } else {
      console.log('You do not have permission to view subscriber details.');
    }
  };

  //#region api get calls
  const getAllSubscribers = async (period) => {
    const response = await getFundsSubscribers(period);
    if (response?.responseCode === 200) {
      const uniqueData = response?.responseData.filter((item, index, self) => index === self.findIndex((t) => t.subscriptionID === item.subscriptionID));
      setSubscribersData(uniqueData);
      setFilteredSubscribers(uniqueData);  // Initialize with unfiltered data
    } else {
      setSubscribersData([]);
      setFilteredSubscribers([]);
    }
  };

  //#region useeffect
  useEffect(() => {
    getAllSubscribers(selectedPeriod);
  }, [selectedPeriod]);

  //#region return
  return (
    <div className='white-card'>
      <div className='space-between align-item-center'>
        <div>
          <h6>New Subscribers</h6>
        </div>
        <div className='child-row-margin-5'>
          <div className='width-100'>
            <SelectField
              name='fundStatus'
              label='Status'
              variant={FieldVariants.OUTLINED}
              value={filters.fundStatus}
              options={subscriberStatusDropdown}
              onChange={(name, value) => handleFilterChange(name, value)}
            />
          </div>
          <div className='width-180'>
            <SelectField
              name='selectedPeriod'
              label='Period'
              variant={FieldVariants.OUTLINED}
              value={selectedPeriod}
              options={subscribersDateOptions}
              onChange={(name, value) => setSelectedPeriod(value)}
            />
          </div>
          <div className='width-180'>
            <SelectField
              name='fundType'
              label='Fund Type'
              variant={FieldVariants.OUTLINED}
              value={filters.fundType}
              options={fundTypes}
              onChange={(name, value) => handleFilterChange(name, value)}
            />
          </div>
        </div>
      </div>
      <div className='height-400'>
        {checkFeaturePermissions(RolesAndPermissions.FUND_SUBSCRIPTION, FeatureAccess.READ) ? (
          <DataGrid
            columns={subscribersColumns}
            rows={filteredSubscribers
              .slice(
                paginationModel.page * paginationModel.pageSize,
                (paginationModel.page + 1) * paginationModel.pageSize
              )
              .map((item) => ({
                id: item.subscriptionID,
                InvestorName: `${item.firstName} ${item.lastName}`,
                ...item,
                subscriptionStatusName: item.subscriptionStatusName === 'Under Compliance Review' ? 'Under Review' : item.subscriptionStatusName,
              }))}
            getRowId={(row) => row.subscriptionID}
            onRowClick={subscriberClick}
            rowHeight={39}
            sx={{
              "& .MuiDataGrid-row:hover": {
                cursor: "pointer"
              },
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            paginationMode="client"
            pageSizeOptions={[10, 20, 50]}
            rowCount={filteredSubscribers.length}
            slots={{
              pagination: GridPagination,
            }}
            slotProps={{
              pagination: {
                labelDisplayedRows: () => `Current Page: ${paginationModel.page + 1}`,
              },
            }}
          />
        ) : (
          <p className='noPermission'>
            <LockPersonIcon />
            You do not have permission to view the Funds
          </p>
        )}
      </div>
    </div>
  );
};

export default NewSubscribersGrid;
