import { Dialog, DialogTitle, DialogActions, Button, DialogContent, Grid } from '@mui/material';
import { useState } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { AppDashboards } from '../../../utils/enum';
import ALTTextField from '../../common/input-fields/ALTTextField';
import ActionButton from '../../common/ActionButton/ActionButton';
import Text from '../../common/Text/Text';
import RecommendIcon from '@mui/icons-material/Recommend';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import InfoIcon from '@mui/icons-material/Info';
//#This popup displays when fund manager wants to approve the investor subscription with out compltinion of compliance review
const FMSubscriptionConfirmationPopup = (props) => {

    //#region props
    const { open, onClose, isApproved, updateSubscriptionStatus, CoupdateSubscriptionStatus, approveBtnLoading, CurrStatus } = props;

    //#region variables
    const [reason, setReason] = useState()
    const [isFirstConfirm, setIsFirstConfirm] = useState(true);
    const [confirmationText, setConfirmationText] = useState('');


    //#region change events
    const handleChange = (name, value) => {
        setReason(value);
    }

    //#region click events
    const onConfirmClick = () => {
        if (isFirstConfirm && secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review') {
            setConfirmationText("By proceeding, you are confirming that you have reviewed the investor's subscription request and are satisfied with its accuracy and completeness. You will be required to electronically sign the investor agreement. Do you wish to proceed and sign the agreement?");
            setIsFirstConfirm(false);
        } else {
            if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD) {
                updateSubscriptionStatus(isApproved);
            } else {
                CoupdateSubscriptionStatus(true, " ");
            }
        }
    }

    const renderSubscriptionPopupText = () => {
        if (isFirstConfirm) {
            if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review') {
                return "Please confirm whether you want to proceed anyway, acknowledging that the compliance review is still pending.";
            } else if (secureLocalStorage.getItem("userrole") !== AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review') {
                return "By proceeding, you confirm that you have reviewed the investor's subscription request and are satisfied with its accuracy and completeness. Do you wish to proceed?";
            } else if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus !== 'Under Compliance Review') {
                return "By proceeding, you are confirming that you have reviewed the investor's subscription request and are satisfied with its accuracy and completeness. You will be required to electronically sign the investor agreement. Do you wish to proceed and sign the agreement?"
            }
        }

        // After the first confirmation click, show the new confirmation text
        return confirmationText;
    }

    const renderConfirmButtonLabel = () => {
        if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review') {
            if (isFirstConfirm) {
                return "Proceed with approval"
            }
            else {
                return "Sign agreement and proceed"
            }
        } else if (secureLocalStorage.getItem("userrole") !== AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review') {
            return "Approve"
        } else if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus !== 'Under Compliance Review') {
            return "Sign agreement and proceed"
        }
    }

    const renderCancelButtonLabel = () => {
        if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review') {
            if (isFirstConfirm) {
                return "Cancel"
            } else {
                return 'Return to review'
            }
        } else if (secureLocalStorage.getItem("userrole") !== AppDashboards.FM_DASHBOARD && CurrStatus === 'Under Compliance Review') {
            return 'Cancel'
        } else if (secureLocalStorage.getItem("userrole") === AppDashboards.FM_DASHBOARD && CurrStatus !== 'Under Compliance Review') {
            return "Return to review"
        }
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>
            <h6> <InfoIcon className='info-i' />
           Confirmation </h6>
            </DialogTitle>
            <DialogContent>
                <p>
                    {renderSubscriptionPopupText()}
                </p>


                {/*  <div className=''>
                   <ALTTextField 
                      name="reason"
                      value={reason}
                      multiline={true}
                      rows={4}
                      onChange={(name,value)=>handleChange(name,value)}/>
                </div> */}
            </DialogContent>
            <DialogActions>
                <div className='space-between'>
                    <div>
                        <ActionButton variant='outlined' disabled={approveBtnLoading} icon={<CancelIcon />} onClick={onClose} label={renderCancelButtonLabel()} />
                    </div>
                    <div className='margin-left-10'>
                        <ActionButton
                            label={renderConfirmButtonLabel()}
                            loading={approveBtnLoading}
                            startIconName=''
                            icon={<CheckCircleIcon />}
                            onClick={onConfirmClick} />
                    </div>
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default FMSubscriptionConfirmationPopup;