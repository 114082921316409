import React, { useEffect, useRef, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { MarkAsRead, getNotificationsbyUserID } from '../../Services/GroupsServices';
import secureLocalStorage from 'react-secure-storage';
import { Grid, LinearProgress } from '@mui/material';
import { ConverttoIST } from '../../../common/Functions/TimeConvestion';
import ContextMenu from '../../../common/ContextMenu/ContextMenu';
import EastIcon from '@mui/icons-material/East';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { Button } from '@mui/material';
import NotificationDetailPopup from './NotificationDetailPopup';
import { AppDashboards } from '../../../../utils/enum';
import DeleteIcon from '@mui/icons-material/Delete';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';

const IndividualNotification = (props) => {
    const { notification } = props;

    //#region variables
    const navigate = useNavigate();
    const [showMore, setShowMore] = useState(false);
    const [open, setOpen] = useState(false);
    const MenuItemsTop = [
        {
            id: 1,
            label: 'Delete notification',
            icon: <DeleteIcon />,
            onClick: () => { }
        },
        {
            id: 2,
            label: 'Turn off notification',
            icon: <NotificationsOffIcon />,
            onClick: () => { }
        }
    ]
    const notificationRef = useRef(null);
    const notificationMaxLength = 150;

    //#region functions
    function convertHtmlToTextAndTruncate(notification) {
        let plainText;

        const hasHtmlTags = /<\/?[a-z][\s\S]*>/i.test(notification);

        if (hasHtmlTags) {
            const tempElement = document.createElement('div');
            tempElement.innerHTML = notification || '';
            plainText = tempElement.textContent || tempElement.innerText || '';
        } else {
            plainText = notification || '';
        }


        return plainText.length > notificationMaxLength
            ? plainText.slice(0, notificationMaxLength) + '...'
            : plainText;
    }


    //#region click events
    const handleOpen = (notification) => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    }

    const handleNotificationClick = async (notification) => {
        const { notificationDetailsID, notificationHasAction, notificationURL, notificationType } = notification;
        const userId = secureLocalStorage.getItem("userId");
        const response = await MarkAsRead(notificationDetailsID, userId);
        if (response?.responseCode === 200) {
            secureLocalStorage.setItem('notificationsLength', false)
            const userRole = secureLocalStorage.getItem('userrole');
            if (userRole === AppDashboards.INV_DASHBOARD) {
                let selectedTabIndex = 0;
                if (notificationHasAction === true) {
                    switch (notificationType) {
                        case "Personal information":
                            selectedTabIndex = 0;
                            navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
                            break;
                        case "Banking information":
                            selectedTabIndex = 1;
                            navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
                            break;
                        case "Investment profile":
                            selectedTabIndex = 2;
                            navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
                            break;
                        case "Subscription details":
                            selectedTabIndex = 3;
                            navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
                            break;
                        case "Marketplace":
                            navigate('/marketplace');
                            break;
                        case "Notify to Investor":
                            navigate(notificationURL);
                            break;
                        case "All funds from marketplace":
                            secureLocalStorage.setItem("FundId", parseInt(notificationURL.match(/\d+$/)[0]));
                            navigate('/fund-details', {
                                state: {
                                    FundID: parseInt(notificationURL.match(/\d+$/)[0]),
                                    InvestorID: secureLocalStorage.getItem("userId"),
                                    SubscriptionID: 0,
                                    navigatefrommarketplace: true,
                                    disableSubscriptionButton: false
                                }
                            });
                            break;
                        case "Fund Subscribed":
                        case "Request Additional Information":
                        case "Info Provided":
                        case "FM Review":
                        case "Investor Review":
                        case "Subscription Cancelled":
                            var fundId = notificationURL.match(/FundId=(\d+)/)[1];
                            var subscriptionId = notificationURL.match(/SubscribtionId=(\d+)/)[1];
                            secureLocalStorage.setItem('InvestorAndFMNotificationsRow', { subscriptionsID: parseInt(subscriptionId), fundID: parseInt(fundId), fromNotifications: true });
                            navigate('/subscription-details');
                            break;
                        case "K1Reporting":
                            secureLocalStorage.setItem("FundId", parseInt(notificationURL.match(/\d+$/)[0]));
                            navigate(`/${notificationURL.split('/')[1]}?goto=2`);
                            break;
                        default:
                            selectedTabIndex = 0;
                            navigate('/all-notifications');
                            break;
                    }
                }
                else {
                    /*  handleOpenDialog(); */
                }
            }
            else if (userRole === AppDashboards.CO_DASHBOARD ||
                userRole === AppDashboards.FM_DASHBOARD) {
                // debugger;
                switch (notificationType) {
                    case "Notify User on Investor Comments":
                    case "Notify User on KYCAML Update":
                        navigate(notificationURL);
                        break;
                    case "Fund Termination":
                        secureLocalStorage.setItem("FundId", parseInt(notificationURL.match(/FundId=(\d+)/)[1]));
                        navigate(notificationURL);
                        break;
                    case "Fund Subscribed":
                    case "Request Additional Information":
                    case "Info Provided":
                    case "FM Review":
                    case "Investor Review":
                    case "Subscription Cancelled":
                        var fundId = notificationURL.match(/FundId=(\d+)/)[1];
                        var subscriptionId = notificationURL.match(/SubscribtionId=(\d+)/)[1];
                        // secureLocalStorage.setItem('fromNotifications', true)
                        secureLocalStorage.setItem('InvestorAndFMNotificationsRow', { subscriptionsID: parseInt(subscriptionId), fundID: parseInt(fundId), fromNotifications: true });
                        navigate('/subscription-details');
                        break;
                }
            } else {
                console.log("User does not have the required role for navigation.");
            }
        } else {
            console.log("Error in marking as read");
        }
    };

    //#region useEffect
    useEffect(() => {
        if (notification.notificationBody?.length > notificationMaxLength) {
            setShowMore(true);
        } else {
            setShowMore(false);
        }
    }, [notificationRef])

    return (
        <>
            <Grid container spacing={2} className='whiteCard' marginTop={2}>
                <Grid item xs={12}>
                    <div className='notifiy-Section'>
                        <div className="notificationCardHeading" onClick={() => handleNotificationClick(notification)}>
                            {notification.notificationSubject}
                        </div>
                        <div className='space-between displaycenter'>
                            <div>
                                {ConverttoIST(notification.updatedDate)}
                            </div>
                            <div>
                                {/* <ContextMenu MenuItemsTop={MenuItemsTop} ItemDetails={notification} /> */}
                            </div>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div ref={notificationRef} style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitBoxOrient: "vertical",
                        WebkitLineClamp: "3",
                        //maxHeight: maxHeight, // Set the height equivalent to 3 lines

                        whiteSpace: 'normal',
                        wordWrap: 'break-word',
                        wordBreak: 'break-word',
                    }}>
                        {convertHtmlToTextAndTruncate(notification?.notificationBody)}
                    </div>
                </Grid>
                <Grid item xs={12}>
                    <div className='notifiy-Section'>
                        <div>
                            {notification.fileNames?.split(',')?.map((file, index) => (
                                <React.Fragment key={index}>
                                    <a
                                        href={notification.containerPath + "/" + notification.notificationDetailsID + "/" + file.trim()}
                                        target="_blank"
                                    >
                                        {file}
                                    </a>
                                    <br />
                                </React.Fragment>
                            ))}
                        </div>
                        <div className="seemore" onClick={() => handleOpen(notification)}>
                            {
                                showMore && <>See More <EastIcon /></>
                            }
                        </div>
                    </div>
                </Grid>
            </Grid>
            {
                open && <NotificationDetailPopup open={open} onClose={handleClose} notificationDetails={notification} />
            }
        </>
    )
}

export default IndividualNotification