import React, { useEffect } from 'react'
import { Dialog, DialogTitle, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Checkbox } from '@mui/material';
import { Chip } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AIPDataGrid from '../../../../../common/DataGrid/AIPDataGrid';
import { justifyContentAllignItem } from '../../../../../CommonCss';
import Text from '../../../../../common/Text/Text';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
const InvestorsListPopup = (props) => {

    //#region Props from Parent
    const { open, onClose ,selectedInvestors,setSelectedInvestors,investorsList,isGroupsPopup} = props;
    console.log('props',props)

    //#region useState Variables

    //#region events
    const InvestorSelectedClick = (params) => {
        if(selectedInvestors?.find(u => u.userId === params.id)){
            const removeSelectedUser = selectedInvestors?.filter(u => u.userId !== params.id);
            setSelectedInvestors(removeSelectedUser);
        }
        else{
            setSelectedInvestors(prevSelectedInvestors => [
                ...prevSelectedInvestors,
                ...investorsList?.filter(u => params.id === u.userId)
            ]);
        }
    };

    //#region Grid Columns
    const Columns = [
        {
            "id": 1,
            "field": 'id',
            "headerName": '_',
            "width": 10,
            renderCell: (params) => (
                <Checkbox
                    checked={(selectedInvestors?.filter(u => u.userId === params.id)?.length > 0)} />
            )
        },
        {
            "id": 2,
            "field": 'userName',
            "headerName": 'Name',
            "width": 250,
        },
        {
            "id": 3,
            "field": 'emailAddress',
            "headerName": 'Email Address',
            "width": 250
        },
        {
            "id": 3,
            "field": 'roleName',
            "headerName": 'Role Name',
            "width": 250
        },
    ];
    

    const GroupsColumns = [
        {
            "id": 1,
            "field": 'id',
            "headerName": '_',
            "width": 10,
            renderCell: (params) => (
                <Checkbox
                    checked={(selectedInvestors?.filter(u => u.userId === params.id)?.length > 0)} />
            )
        },
        {
            "id": 2,
            "field": 'userFullName',
            "headerName": 'Name',
            "width": 250,
        },
        {
            "id": 3,
            "field": 'userEmailAddress',
            "headerName": 'Email Address',
            "width": 250
        },
        {
            "id": 3,
            "field": 'userRoleName',
            "headerName": 'Role Name',
            "width": 250
        },
    ];


    const handleSave = () => {
        onClose();
    }

    const onCancelClick = ()=> {
        setSelectedInvestors([])
    }
    
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" >
            <DialogTitle sx={justifyContentAllignItem}>
                <Text label="All Users" />
                <IconButton edge="end" color="inherit" onClick={onClose} aria-label="close">
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <AIPDataGrid
                    columns= { isGroupsPopup ? GroupsColumns: Columns }
                    rows={investorsList?.map((u) => {
                        return {
                            id: u.userId, ...u
                        }
                    }) || []}
                    handleRowClick={InvestorSelectedClick}
                    onRowsSelectionHandler={() => { }}
                />
            </DialogContent>
            <DialogActions>
                <div onClick={onCancelClick}>
                <Button onClick={onClose} className='btn-primary' variant='outlined'>
            <CancelIcon/>
                    Cancel 
                </Button>
                </div>
                <Button onClick={handleSave} variant='contained' className='btn-primary'>
                <AddIcon/>
                   Add  
                </Button>
               
            </DialogActions>
        </Dialog>
    )
}

export default InvestorsListPopup