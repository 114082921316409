import React, { useEffect, useState } from 'react'
import VDRoom from '../../../common/VirtualDataRoom/VDRoom'
import AppNotifications from '../../../common/AppNotification/AppNotificationComponent';
import FundTransactions from '../../../common/FundTransactions/FundTransactions';
import FundNews from '../../../common/FundNews/FundNews';
import { Grid } from '@mui/material';
import secureLocalStorage from 'react-secure-storage';
import { AppDashboards, FeatureAccess, RolesAndPermissions } from '../../../../utils/enum';
import { checkFeaturePermissions } from '../../../../utils/common';
import { getFundByInvestorId } from '../../../dashboards/services/DashboardService';
import InvSubscriptionDetails from './InvSubscriptionDetails';
import InvestorTransactionsGrid from '../../../fm-subscribers/components/InvestorTransactionsGrid';
import { transactionTypesEnum } from '../../../fm-subscribers/services/apiDataTemplate';
import { getSuggestedArticlesByFundID } from '../../../FundManagerDashboard/Services/FundNewsService';

const InvestorOverview = (props) => {


  //#region props
  const { investorId, fundId } = props;

  //#region vaiables
  const [fundDetails, setFundDetails] = useState();
  const [fundNewsData, setFundNewsData] = useState(null);

  //#region api get calls
  const getFundDetails = async () => {
    const data = await getFundByInvestorId(investorId, fundId);
    if (data.responseCode === 200) {
      const fundDetails = data.responseData?.find(fund => fund?.fundID === fundId);
      setFundDetails(fundDetails);
    }
    else {
      console.error(data.error);
    }
  }

  const getFundNews = async () => {
    const data = await getSuggestedArticlesByFundID(fundId);
    if (data.responseCode === 200) {
      setFundNewsData(data.responseData);
    }
    else {
    }
  }

  //#region useeffect
  useEffect(() => {
    getFundDetails();
    getFundNews();
  }, [fundId])

  //#region return
  return (
    <div className='mt-page-2'>
      <h3>Investment Overview</h3>
      <Grid xs={12} className='margin-top-15 margin-bottom-20'>
        <InvSubscriptionDetails fundDetails={fundDetails} fundId={fundId} />
      </Grid>
      <Grid container className='margin-bottom-20' spacing={2}>
        <Grid item xs={12} md={6} >
          <div className='white-card height-560'>
            <FundNews FundNewsData={fundNewsData} role={AppDashboards.INV_DASHBOARD} />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="white-card">
            <h6>Notifications</h6>
            <div className='height-500'>
              {
                checkFeaturePermissions(RolesAndPermissions.FUND_SUBSCRIPTION, FeatureAccess.READ) &&
                <AppNotifications crntFundID={fundId} crntInvestorid={investorId} />
              }
            </div>
          </div>
        </Grid>
      </Grid>
      <Grid container className='margin-bottom-20' spacing={2}>
        <Grid item xs={12} md={6}>
          <div className="white-card height-525">
            <VDRoom
              VDRType={'Fund'}
              isCompliance={true}
              cnrtFundID={fundId}
              crntSubscriptionID={0} />
          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div className="white-card height-525">
            <InvestorTransactionsGrid type={transactionTypesEnum.INVESTMENT_TRANSACTIONS} investorID={investorId} gridHeight={450} />
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default InvestorOverview