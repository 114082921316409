import React, { useCallback, useEffect, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { alltiresDummyRows, distributionPagesEnum, distributionStatusEnum, distributionsJsonDATA, initialAddDistributionValues, previouDistributionsJsonData } from '../apiDataTemplate';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { getRoles } from '../../../FundManagerDashboard/Services/FundNewsService';
import { DataGrid } from '@mui/x-data-grid';
import { formatDate } from '../../../common/Functions/TimeConvestion';
import secureLocalStorage from 'react-secure-storage';
import { getFundDistributionsbyID } from '../../services/services';
import { getKeyValuePairs } from '../../../portfolio-companies/services/services';

//#this component is to display previous distibutions and tiers 
const DistributionsActivity = (props) => {

  //#region props
  const { currPage, setCurrPage, setSelectedDistribution, previousDistributions, setPreviousDistributions, distributionsDetails, setDistributionsDetails } = props;

  //#region varibales
  const navigate = useNavigate();
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState([]);
  const [tierStatues, setTierStatues] = useState();
  const [expanded, setExpanded] = useState({});
  const columns = [
    {
      field: 'dtDate', headerName: 'Created Date', width: 250,
      renderCell: (params) => formatDate(params.value),
    },
    { field: 'fdStatus', headerName: 'STATUS', width: 250 },
    { field: 'distributionAmount', headerName: 'DISTRIBUTION AMOUNT', width: 250 },
    { field: 'noOfTiers', headerName: 'NUMBER OF TIERS', width: 250, },
  ];
  const tierColumns = [
    { field: 'id', headerName: 'Distribution ID', width: 150 },
    {
      field: 'dtDate', headerName: 'TIER DISTRIBUTION DATES', width: 200,
      renderCell: (params) => formatDate(params.value),
    },
    { field: 'tier', headerName: 'TIER NAME', width: 150 },
    { field: 'distributionStatus', headerName: 'TIER STATUS', width: 150 },
    { field: 'dtAllocationAmount', headerName: 'ALLOCATED AMOUNT', width: 150 },
    { field: 'dtAllocationTypeName', headerName: 'TIER TYPE', width: 150 },
    { field: 'noOfInvestors', headerName: 'NO OF INVESTORS', width: 150 },
  ];

  const diableAddDistribution = () => {
    if (!distributionsDetails) {
      return false;
    }
    else {
      if (distributionsDetails.some(u => u.fdStatus === distributionStatusEnum.ACTIVE)) {
        return true;
      }
      else {
        return false
      }
    }
  }

  //#region change events
  const handleDetailPanelExpandedRowIdsChange = useCallback((rowids) => {
    setDetailPanelExpandedRowIds(
      rowids.length > 1 ? [rowids[rowids.length - 1]] : rowids
    );
  }, []);

  //#region click events
  const handleAccordionClick = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const onAddDistibutionClick = () => {
    getTierDetailsbyID(0, 0)
  }

  const onAddTierClick = async (distributionID) => {
    const currdistributionID = distributionID;
    getTierDetailsbyID(currdistributionID, 0)
  }

  const onTierClick = (params) => {
    setSelectedDistribution(params.row);
    const currdistributionID = params.row.distributionID;
    getTierDetailsbyID(currdistributionID, params.row.distributionTierID);
  }

  //#region api get calls
  const getTierStatues = async () => {
    const data = await getKeyValuePairs("Tiers");
    if (data.responseCode === 200) {
      setTierStatues(data.responseData);
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  const getTierDetailsbyID = async (distributionID, tierID) => {
    setCurrPage(null);
    const fundId = secureLocalStorage.getItem("FundId");
    const data = await getFundDistributionsbyID(fundId, distributionID, tierID);
    if (data.responseCode === 200) {
      let updatedData = data.responseData;
      if (tierID === 0) {
        updatedData.distributionStatus = distributionStatusEnum.DRAFT;
        updatedData.tierID = distributionID === 0 ? tierStatues[0]?.listItemID : tierStatues[distributionsDetails.find((distribution) => distribution.distributionID === distributionID)?.allTiers.length]?.listItemID;
      }
      setSelectedDistribution(updatedData);
      setTimeout(() => {
        setCurrPage(distributionPagesEnum.ADD_EDIT_DISTRIBUTION);
      }, [50])
    }
    else {
      //console.log('unable to fetch data)
    }
  }

  //#region useeffect
  useEffect(() => {
    if (previousDistributions) {

      let sortedDistributions = previousDistributions?.reduce((acc, item) => {
        if (!acc.find(elem => elem.distributionID === item.distributionID)) {
          acc.push({ ...item, allTiers: [] });
        }
        return acc;
      }, []);

      const sortedDistributionsIds = sortedDistributions?.map((item) => item.distributionID)

      const mappedTiersandDistributions = previousDistributions?.map((item) => {
        if (sortedDistributionsIds.includes(item.distributionID)) {
          const crtIndex = sortedDistributions.findIndex((sd) => sd.distributionID === item.distributionID)
          sortedDistributions[crtIndex].allTiers = [...(sortedDistributions[crtIndex].allTiers || []), item];
        }
      });

      setDistributionsDetails(sortedDistributions);

    }
  }, [previousDistributions])

  useEffect(() => {
    getTierStatues();
  }, [])

  //#region render components
  const getDetailPanelContent = ({ row }) => (
    <div className='child-margin-10'>
      <div className='space-between'>
        <div></div>
        <div>
          {
            row?.allTiers?.length >= 5 ? "" :
              <Button
                variant="outlined"
                className='btn-primary'
                startIcon={<AddIcon />}
                onClick={() => onAddTierClick(row.distributionID)}
                disabled={
                  row?.allTiers?.some((tier) => {
                    return (
                      tier.distributionStatus === distributionStatusEnum.ACTIVE ||
                      tier.fdStatus === distributionStatusEnum.EXECUTED
                    );
                  })
                }>
                ADD NEW TIER
              </Button>
          }
        </div>
      </div>
      <div className='height-200'>
        <DataGrid
          columns={tierColumns}
          rows={
            row?.allTiers?.length > 0 ?
              row?.allTiers?.map((item) => {
                return {
                  id: item.tierID,
                  ...item
                }
              }) : []
          }
          onRowClick={onTierClick}
          hideFooter />
      </div>
    </div>
  );

  //#region return
  return (
    <div className='wrapper mt2-page child-margin-15'>
      <div className='space-between'>
        <div>{currPage === distributionPagesEnum.DISTRIBUTION_ACTIVITY && <h3>Distribution Activity</h3>}</div>
        <div>
          <Button
            variant="outlined"
            className='btn-primary'
            startIcon={<AddIcon />}
            onClick={onAddDistibutionClick}
            disabled={diableAddDistribution()}
          // {distributionsDetails?.length >= 1 ? (distributionsDetails[distributionsDetails.length - 1].distributionStatus === distributionStatusEnum.ACTIVE) :  false}
          //disabled={distributionsDetails?.length >= 1}
          >
            ADD NEW DISTRIBUTION
          </Button>
        </div>
      </div>
      <div className='white-card'>
        <h6>Previous Distributions</h6>
        {
          distributionsDetails ?
            <DataGridPro
              columns={columns}
              rows={distributionsDetails?.length > 0 ?
                distributionsDetails?.map((tier, index) => {
                  return {
                    id: tier.distributionID,
                    ...tier
                  }
                })
                : []
              }
              getDetailPanelContent={getDetailPanelContent}
              detailPanelExpandedRowIds={detailPanelExpandedRowIds}
              onDetailPanelExpandedRowIdsChange={handleDetailPanelExpandedRowIdsChange}
              hideFooter /> : <div className='align-items-center'>No Previous Distributions</div>
        }
      </div>
    </div>
  )
}

export default DistributionsActivity;