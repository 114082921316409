import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getCompareFunds } from '../../../../state/slices/MarketPlaceSlice';
import FundBoxView from './FundBoxView';
import { getHeaderColorConfig, getrootBackgroundColorConfig } from '../../../../state/slices/colorConfigSlice';
import { getAllFunds } from '../../../../state/slices/MarketPlaceSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import { FundAddTypes, MarketPlacePagesEnum } from '../../mpenum';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import { getFundSubscriptions } from '../../services/mpservices';
import secureLocalStorage from 'react-secure-storage';
import CircleIcon from '../../../chatbot/Icon';
import { HeaderTemplates, PageHeader } from '../../../common/PageHeader/PageHeader';

const CompareFunds = (props) => {

 //#region props
 const { handlePageChange } = props;

 //#region redux variables
 const rootBackColorConfig = useSelector(getrootBackgroundColorConfig);
 const dispatch = useDispatch();
 const navigate = useNavigate();
 let comparefunds = useSelector(getCompareFunds);
 const allFundsDetails = useSelector(getAllFunds);
 const [allFunds,setAllFunds] = useState(null);
 const [allInvestors,setAllInvestors] = useState();
 const location = useLocation();
 const { state } = location;
//  comparefunds = state.fundTypeName == undefined ? comparefunds : comparefunds?.filter(a=> a.fundTypeName == state.fundTypeName);

 //#region api get calls
 const getFundsSubscriptions = async() => {
  const data = await getFundSubscriptions();
  if(data.responseCode === 200){
      setAllInvestors(data.responseData.filter(u=>u.investorID === secureLocalStorage.getItem("userId") && u.subscriptionStatus !== "Rejected" && u.subscriptionStatus !== "Cancelled"));
  }
  else{
      console.log('unable to load funds')
  }
}

  //#region click events
  const handleAddClick = () =>{
    navigate('/marketplace')
  }

  const handleBackClick = () =>{
    handlePageChange(MarketPlacePagesEnum.MAIN)
  }


  //#region whitelabiling
  const headerColorConfig = useSelector(getHeaderColorConfig);

 //#region useEffect
  useEffect(()=>{
    setAllFunds(allFundsDetails.value);
  },[allFundsDetails])

  useEffect(()=>{
    getFundsSubscriptions();
  },[])

  return (
    <div>
      <PageHeader
          title="Compare Funds"
          template={HeaderTemplates.NORMAL_VIEW}
          isNavigate={handleBackClick} />
  
    <div className='wrapper mt2'>
        <div className='mp-wrapper'>
            {
              allFunds && allInvestors && comparefunds?.length > 0 && comparefunds?.map((item,index)=>(
                  <div key={index}>
                      <FundBoxView allFunds={allFunds} allInvestors={allInvestors} fundDetails={item} type={FundAddTypes.COMPARE} />
                  </div>
              ))
            }
            <div className='add-box cursor-pointer' onClick={handleBackClick}>
               <div className='add-box2 cursor-pointer'>
                  <div className='add-icon-center'>
                       <ControlPointIcon color="action"/>
                  </div>
               </div>
            </div>
       </div>
       <CircleIcon/>
    </div>
    </div>
  )
}

export default CompareFunds