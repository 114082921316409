import { useState, useEffect, memo, useRef } from "react";
import FileUploadButton from "../../../common/FileUploadButton/FileUploadButton";
import Text from "../../../common/Text/Text";
import './styles.css';
import json from '../../filterbox.json'
import secureLocalStorage from "react-secure-storage";
import { InitialFundManagers } from "./InitialFundData";
import ActionButton from "../../../common/ActionButton/ActionButton";
import Button from "@mui/material/Button";
import DeleteIcon from '@mui/icons-material/Delete';
import AppTextInput from "../../../common/TextInput/AppTextField";
import { validationsTypeEnum, InputTypes, validationMessages, textFormatTypeEnum } from "../../../common/TextInput/appInputenum";
import { ToastContainer, toast } from 'react-toastify';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import AddIcon from '@mui/icons-material/Add';
import { Checkbox, FormControlLabel, TextField } from "@mui/material";
import CheckBoxField from "../../../common/input-fields/SwitchButtonField";
import SwitchButtonField from "../../../common/input-fields/SwitchButtonField";
import AutoSelect from "../../../common/TextInput/AutoSelect";
import { FundTypes } from "./InitialFundData";
import { InfoOutlined } from "@mui/icons-material";
import FileUploadBtn from "../../../common/FileUploadButton/FileUploadBtn";
import { nanoid } from "@reduxjs/toolkit";
// import { getFundFilterData } from "../../../marketplace/services/MarketplaceService";

const FundDetails = (props) => {
    //#region props
    const { firmDetails, fundStaffData } = props;

    //#region intialdata
    const initialFundDetails = {
        fundStaff: [],
        fundTypeID: '',
        //fundName: '',
        fundTypeName: '',
        //fundName: '',
        iconImage: '',
        bgImage: '',
        fundDescription: '',
        StrategyName: '',
        GeographicFocusName: '',
        TargetIndustry: '',
        LiquidityProvision: "",
        investmentStrategyId: '',
        fundTargetIndustryId: '',
        geographicFocusId: '',
        fundSize: '',
        minimumInvestmentAmount: '',
        fundStatus: '',
        vintage: 2024,
        targettedIRR: '',
        investmentPeriodCriteria: '',
        InvestmentCycle: '',
        visibility: '0',
        fundManager: '',
        legalStructureID: '',
        ...props.data,
    }

    const initialFundManagers = {
        id: 0,
        userID: 0,
        description: ""
    }

    //#region variables
    const componentRef = useRef(null);
    const [fundDetails, setFundDetails] = useState(initialFundDetails);
    const [selectedManagers, setSelectedManagers] = useState({});
    const [error, setErrors] = useState({})
    const iFundManagers = fundDetails?.fundStaff?.map((item, index) => {
        const matchingStaffDataItem = props?.fundStaffData?.find(dataItem => dataItem.value === item);
        return {
            id: item,
            userID: item,
            description: matchingStaffDataItem?.briefDescription || ''
        };
    });
    /* debugger */
    const hasIFundManagers = iFundManagers && iFundManagers.length > 0;
    const [fundManagers, setFundManagers] = useState(hasIFundManagers ? iFundManagers : [InitialFundManagers]);
    const [isMultifieldsValid, setMultifieldsValid] = useState(true);
    const [noofValidFields, setNoofValidFields] = useState({ validfields: 0 });
    const [checkIfValid, setcheckIfValid] = useState(false);

    //#region functions
    const generateUniqueID = (existingIDs) => {
        const maxID = Math.max(...existingIDs, 0); // Find the maximum existing ID
        const newID = maxID + 1;
        return newID;
    };

    //#region change events
    const handleFileUpload = (name, file) => {
        if (file) {
            const formData = new FormData();
            formData.append(name, file, file.name);
            handleChange(name, file);
        }
    }

    const handleChangeFM = (field, value) => {

        const findManager = props?.fundStaffData?.find((item) => item.value === value);
        const matchid = field.match(/\d+/);
        const findIndex = matchid ? fundManagers.findIndex((item) => item.id == matchid) : -1;

        const currState = [...fundManagers];
        if (findIndex !== -1 && findIndex < currState.length) {
            currState[findIndex] = {
                ...currState[findIndex],
                userID: value,
                description: findManager?.briefDescription || '',
            };
        } else if (currState.length === 1) {
            currState[0].userID = value;
            currState[0].description = findManager.briefDescription;
        } else {
            console.error("Invalid index or findIndex not found");
        }

        //setting the dropdown disabled users
        const existingManagementTeamIds = Array.isArray(props.fundData?.FundManagementTeams) ?
            fundStaffData
                ?.map((item) => {
                    const isDisabled = props.fundData?.FundManagementTeams?.find((user) => user.managementTeamStaff === item.value);
                    return isDisabled ? item.value : null;
                })
                .filter((value) => value !== null) : [];

        const extractids = currState.map((item) => item.userID);

        const extractedids = [...existingManagementTeamIds, ...extractids]

        const newFundStaff = fundStaffData
            .map((item) =>
                extractedids.includes(item.value)
                    ? { ...item, disabled: true }
                    : { ...item, disabled: false }
            )

        props.setFundStaffDropdown(newFundStaff);

        //set the fundStaff Value
        const idarray = currState.map((item) => item.userID)
        handleChange('fundStaff', idarray)
        // Set the state with the modified copy
        setFundManagers(currState);
        const id = field.replace('fundmanager', '');
        setSelectedManagers(prev => ({
            ...prev,
            [id]: value
        }));

        props.setFMDisabledFMOptions(prev => ({
            ...prev,
            [id]: value
        }))

    }

    const handleChange = (field, value) => {
        const currState = { ...fundDetails };
        if (field !== 'investmentStrategyId' && field !== 'fundTargetIndustryId' && field !== 'geographicFocusId' && field !== "liquidity") {
            currState[field] = value;
        }

        if (field === 'fundSize' || field === 'minimumInvestmentAmount') {
            const fundSize = parseInt(currState['fundSize']);
            const minimumInvestmentAmount = parseInt(currState['minimumInvestmentAmount']);
            if (fundSize < minimumInvestmentAmount) {
                setMultifieldsValid(false);
            }
            else {
                setMultifieldsValid(true);
            }
        }

        if (field === 'investmentStrategyId') {
            if (!props.fundFilterData.strategy.some(option => option.strategyName === value) && !props.fundFilterData.strategy.some(option => option.investmentStrategyID === value)) {
                if (props.fundFilterData.strategy.some(option => option.investmentStrategyID === -1)) {
                    props.fundFilterData.strategy = props.fundFilterData.strategy.filter(option => option.investmentStrategyID !== -1);
                    props.fundFilterData.strategy.push({
                        investmentStrategyID: -1, strategyName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["StrategyName"] = value
                    setFundDetails(currState);
                } else {
                    props.fundFilterData.strategy.push({
                        investmentStrategyID: -1, strategyName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["StrategyName"] = value
                    setFundDetails(currState);
                }

                ///const currState = { ...fundDetails };
            } else {
                currState[field] = value;
                currState["StrategyName"] = props.fundFilterData.strategy.find(option => option.investmentStrategyID === value).strategyName
                setFundDetails(currState);
            }
            if (value !== '') {
                setErrors({ ...error, investmentStrategyId: false })
            } else {
                setErrors({ ...error, investmentStrategyId: true })
            }
        }
        if (field === 'fundTargetIndustryId') {

            if (!props.fundFilterData.industry.some(option => option.targetIndustry === value) && !props.fundFilterData.industry.some(option => option.fundTargetIndustryID === value)) {
                if (props.fundFilterData.industry.some(option => option.fundTargetIndustryID === -1)) {
                    props.fundFilterData.industry = props.fundFilterData.industry.filter(option => option.fundTargetIndustryID !== -1);
                    props.fundFilterData.industry.push({
                        fundTargetIndustryID: -1, targetIndustry: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["TargetIndustry"] = value
                    setFundDetails(currState);
                } else {
                    props.fundFilterData.industry.push({
                        fundTargetIndustryID: -1, targetIndustry: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["TargetIndustry"] = value
                    setFundDetails(currState);
                }
            } else {
                currState[field] = value;
                currState["TargetIndustry"] = props.fundFilterData.industry.find(option => option.fundTargetIndustryID === value).targetIndustry
                setFundDetails(currState);
            }

            if (value !== '') {
                setErrors({ ...error, fundTargetIndustryId: false })
            } else {
                setErrors({ ...error, fundTargetIndustryId: true })
            }
        }
        if (field === 'geographicFocusId') {
            if (!props.fundFilterData.geographicFocus.some(option => option.geographicFocusName === value) && !props.fundFilterData.geographicFocus.some(option => option.geographicFocusId === value)) {
                if (props.fundFilterData.geographicFocus.some(option => option.geographicFocusId === -1)) {
                    props.fundFilterData.geographicFocus = props.fundFilterData.geographicFocus.filter(option => option.geographicFocusId !== -1);
                    props.fundFilterData.geographicFocus.push({
                        geographicFocusId: -1, geographicFocusName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["GeographicFocusName"] = value
                    setFundDetails(currState);

                } else {
                    props.fundFilterData.geographicFocus.push({
                        geographicFocusId: -1, geographicFocusName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState["GeographicFocusName"] = value
                    setFundDetails(currState);
                }
            } else {
                currState[field] = value;
                currState["GeographicFocusName"] = props.fundFilterData.geographicFocus.find(option => option.geographicFocusId === value).geographicFocusName
                setFundDetails(currState);
            }

            if (value !== '') {
                setErrors({ ...error, geographicFocusId: false })
            } else {
                setErrors({ ...error, geographicFocusId: true })
            }
        }
        if (field === 'InvestmentCycle') {
            if (!props.fundFilterData.liquidityProvision.some(option => option.liquidityProvisionName === value) && !props.fundFilterData.liquidityProvision.some(option => option.liquidityProvisionId === value)) {
                if (props.fundFilterData.liquidityProvision.some(option => option.liquidityProvisionId === -1)) {
                    props.fundFilterData.liquidityProvision = props.fundFilterData.liquidityProvision.filter(option => option.liquidityProvisionId !== -1);
                    props.fundFilterData.liquidityProvision.push({
                        liquidityProvisionId: -1, liquidityProvisionName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState['LiquidityProvision'] = value
                    setFundDetails(currState);
                } else {
                    props.fundFilterData.liquidityProvision.push({
                        liquidityProvisionId: -1, liquidityProvisionName: value, strategyDescription: null
                    });
                    currState[field] = -1;
                    currState['LiquidityProvision'] = value
                    setFundDetails(currState);
                }
            } else {
                currState[field] = value;
                currState["LiquidityProvision"] = Array.isArray(props.fundFilterData.liquidityProvision) ? (props.fundFilterData.liquidityProvision.find(option => option.liquidityProvisionId === value)?.liquidityProvisionName || '') : '';
                setFundDetails(currState);
            }

            // if (value !== '') {
            //     setErrors({ ...error, liquidityProvisionId: false })
            // } else {
            //     setErrors({ ...error, liquidityProvisionId: true })
            // }
        }
        if (field === 'investmentPeriodCriteria') {
            if (value === '') {
                currState['investmentPeriodCriteria'] = '';
            } else {
                currState['investmentPeriodCriteria'] = parseInt(value);
            }
        }

        if (field === "isFirmAsGP" && value === true) {
            /*  console.log(props.disabledFMoptions)
             const array = [props.disabledFMoptions]
             const filterFms = array?.filter((item)=> !fundManagers?.includes(item)) */
            const extractedids = fundManagers?.map((item) => item.id)
            const newFundStaff = fundStaffData
                .map((item) =>
                    extractedids.includes(item.value)
                        ? { ...item, disabled: false }
                        : { ...item, disabled: false }
                )
            props.setFundStaffDropdown(newFundStaff);
            props.setFMDisabledFMOptions({});
            setFundManagers([initialFundManagers]);
            currState["fundStaff"] = []
        }
        setFundDetails(currState);
    }

    //#region click events
    const addFundManager = () => {
        /*  if (fundManagers.length > 5) {
             return;
         } */

        const existingIDs = fundManagers.map(manager => manager.id);

        // Dynamically generate a unique ID greater than the last element's ID
        const newManager = { id: generateUniqueID(existingIDs), userID: "", description: "" };

        setFundManagers([...fundManagers, newManager]);
    };

    const deleteFundManager = (fundmanager) => {
        if (fundManagers.length <= 1) {
            return;
        }
        const remainingfm = fundManagers.filter((item) => item.id !== fundmanager.id);
        const idarray = remainingfm.map((item) => item.userID)
        handleChange('fundStaff', idarray)
        setFundManagers(remainingfm);
        props.setFundStaffDropdown((prevItems) =>
            prevItems.map((item) =>
                item.value === fundmanager.userID ? { ...item, disabled: false } : item
            )
        );

        setSelectedManagers((prev) => {
            const updated = { ...prev };
            delete updated[fundmanager.id];
            return updated;
        });
        props.setFMDisabledFMOptions((prev) => {
            const updated = { ...prev };
            delete updated[fundmanager.id];
            return updated;
        })
    }

    //#region validations
    const checknoofValidFields = (isValid, name, defaultValue) => {
        const dynamicFieldsCount = fundDetails?.isFirmAsGP ? 0 : fundManagers?.length;
        const TotalFiledsToValidate = 6 + dynamicFieldsCount;
        const errors = {}
        if (fundDetails?.investmentStrategyId === '') {
            errors.investmentStrategyId = true
        }
        if (fundDetails?.fundTargetIndustryId === '') {
            errors.fundTargetIndustryId = true
        }
        if (fundDetails?.geographicFocusId === '') {
            errors.geographicFocusId = true
        }
        // if (fundDetails.InvestmentCycle === '') {
        //     errors.liquidityProvisionId = true
        // }
        setErrors(errors)
        noofValidFields['validfields'] = isValid ? noofValidFields['validfields'] + 1 : noofValidFields['validfields'];
        if (noofValidFields['validfields'] === TotalFiledsToValidate) {
            noofValidFields['validfields'] = 0;
            if (isMultifieldsValid) {
                if (error.length === 0 || error.length === undefined) {
                    props.setFundDetailErrors(false);
                    props.setStep(currStep => currStep + 1);
                }
            }
        }
    };

    const handleSave = () => {
        setcheckIfValid(true);
        if (!isMultifieldsValid) {
            /* alert('Target Raised should be greater than Minimum Investment Amount'); */
            toast.warning(`Target Raise should be greater than Minimum Investment Amount`,
                { position: toast.POSITION.BOTTOM_RIGHT, theme: "colored" });
        }
        noofValidFields['validfields'] = 0;
    }

    //#region useeffect
    useEffect(() => {
        componentRef.current.scrollIntoView({ behavior: 'smooth' });
    }, []);

    useEffect(() => {
        return () => {
            props?.updateData(fundDetails);
        }
    }, [fundDetails]);

    useEffect(() => {
        if (props.FundDetailerrors === true) {
            handleSave();
        }
    }, [props.FundDetailerrors])

    console.log('funddetails', fundDetails)

    //#region return
    return (
        <div ref={componentRef}>
            <div className="fundRow">
                {/* <div className="fundDetail">
                    <AppTextInput
                            textFormatType={textFormatTypeEnum.ALPHA_NUMERIC}
                            name="fundName"
                            label="Fund Name"
                            defaultValue={fundDetails?.fundName}
                            onChange={(name, value) => handleChange(name, value)}
                            validateField={true}
                            validationType={validationsTypeEnum.REQUIRED}
                            validationMessage={validationMessages.REQUIRED}
                            checkIfValid={checkIfValid}
                            checknoofValidFields={checknoofValidFields}
                            setcheckIfValid={setcheckIfValid}
                        />
                </div> */}
            </div>
            <div className="fundLogoWrapper">
                {/* {errormessage && <span className="error-message">{errormessage}</span>} */}
                <FileUploadBtn
                    name="iconImage"
                    // label={<span className="requiredField">UPLOAD LOGO</span>}
                    label="UPLOAD LOGO"
                    defaultFile={fundDetails?.iconImage}
                    isImage={true}
                    maxSize={5}
                    onChange={(name, value) => handleFileUpload(name, value)}
                /* errormessage={errors.iconImage || undefined} */
                />
            </div>
            <div>
                {/* <div className="fundBackgroundLabel">Image for the background of Fund Detail page</div> */}
                <div className="fundLogoWrapper mt1">
                    <FileUploadBtn
                        name="bgImage"
                        // label={<span className="requiredField">UPLOAD IMAGE</span>}
                        label="UPLOAD FUND BANNER IMAGE"
                        defaultFile={fundDetails?.bgImage}
                        isImage={true}
                        maxSize={5}
                        onChange={(name, value) => handleFileUpload(name, value)}
                    /*  errormessage={errors.bgImage || undefined} */
                    />
                </div>
            </div>
            <div className="fundRow">
                <AppTextInput
                    textFormatType="text"
                    name="fundDescription"
                    placeholder="Brief Description About the Fund"
                    multiline={true}
                    rows={2}
                    variant="outlined"
                    defaultValue={fundDetails?.fundDescription}
                    charactersMaxLength={500}
                    onChange={(name, value) => handleChange(name, value)}
                    validateField={false}
                />
            </div>
            <div className="fundRow space-between">
                <div><Text label="Fund manager / General Partner (GP)" /></div>
                <div>

                </div>
            </div>

            <div className="fundRow space-between res-spacebetween2">
                <div>
                    <SwitchButtonField
                        name="isFirmAsGP"
                        label="Firm as a fund manager"
                        value={fundDetails?.isFirmAsGP}
                        onChange={(name, value) => handleChange(name, value)} />
                </div>
                <div>
                    <ActionButton
                        variant="outlined" className='btn-primary'
                        icon={<AddIcon />}
                        label={"Add Fund Manager"}
                        disabled={fundDetails?.isFirmAsGP}
                        onClick={() => addFundManager()}
                    />
                </div>
            </div>

            {fundDetails?.isFirmAsGP &&
                <div className="fundRow">
                    <TextField
                        fullWidth
                        variant="outlined"
                        multiline={true}
                        name='FirmDescription'
                        value={firmDetails?.firmDescription}
                        rows={2}
                        placeholder="Firm Description"
                        InputProps={{
                            readOnly: true,
                        }}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={false} />
                </div>
            }

            {/* FundManagers */}
            {!(fundDetails?.isFirmAsGP) &&
                fundManagers?.map((item, index) => (
                    <div key={item.id} className="fundManagerRow">
                        <div className="fundManager-name">
                            <AppTextInput
                                type={InputTypes.SELECT}
                                label="Fund Manager"
                                name={`fundmanager${item.id}`}
                                defaultValue={item?.userID === 0 ? '' : item?.userID}
                                options={props?.fundStaffData
                                    ? props?.fundStaffData.map(option => ({
                                        label: option?.label,
                                        value: option?.value,
                                        isdisabled: option?.disabled
                                    }))
                                    : []
                                }
                                disabled={fundDetails?.isFirmAsGP}
                                onChange={(name, value) => handleChangeFM(name, value)}
                                required={true}
                                validateField={true}
                                validationType={validationsTypeEnum.REQUIRED}
                                validationMessage={validationMessages.REQUIRED}
                                checkIfValid={checkIfValid}
                                checknoofValidFields={checknoofValidFields}
                                setcheckIfValid={setcheckIfValid}
                            />
                        </div>
                        <div className="fundManager-Description">
                            {/* <input
                                name='Manager Description'
                                placeholder="Manager Description"
                                disabled={true}
                                value={item.description}
                                /> */}
                            <TextField
                                fullWidth
                                variant="outlined"
                                multiline={true}
                                name='ManagerDescription'
                                value={item?.description}
                                rows={1}
                                placeholder="Manager Description"
                                InputProps={{
                                    readOnly: true,
                                }}
                                onChange={(name, value) => handleChange(name, value)}
                                validateField={false}
                            />
                        </div>
                        <div className="fundManager-Delete">
                            {
                                index === 0 ? "" :
                                    <Button
                                        onClick={() => { deleteFundManager(item) }}
                                        sx={{ color: 'black' }}
                                        disabled={fundDetails?.isFirmAsGP}>
                                        <HighlightOffIcon />
                                    </Button>
                            }

                        </div>
                    </div>
                ))
            }
            <div className="fundRow"><Text label="What is the fund’s investment strategy" /></div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AutoSelect
                        type={InputTypes.AUTOSELECT}
                        variant="standard"
                        name="investmentStrategyId"
                        label="Investment Strategy"
                        defaultValue={fundDetails?.investmentStrategyId}
                        options={props?.fundFilterData?.strategy?.map(option => ({ label: option.strategyName, value: option.investmentStrategyID }))}
                        onChange={(name, value) => handleChange(name, value)}
                        required={true}
                        error={error.investmentStrategyId}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
                <div className="fundDetail">
                    <AutoSelect
                        type={InputTypes.AUTOSELECT}
                        variant="standard"
                        name="fundTargetIndustryId"
                        label="Target Industry/Sector"
                        required={true}
                        defaultValue={fundDetails?.fundTargetIndustryId}
                        error={error.fundTargetIndustryId}
                        options={props?.fundFilterData?.industry?.map(option => ({ label: option.targetIndustry, value: option.fundTargetIndustryID }))}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AutoSelect
                        type={InputTypes.AUTOSELECT}
                        variant="standard"
                        name="geographicFocusId"
                        label="Geographic Focus"
                        required={true}
                        error={error.geographicFocusId}
                        defaultValue={fundDetails?.geographicFocusId}
                        options={props?.fundFilterData?.geographicFocus?.map(option => ({ label: option.geographicFocusName, value: option.geographicFocusId }))}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
                <div className="fundDetail">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.US_CURRENCY}
                        name="fundSize"
                        label="Target Raise"
                        defaultValue={fundDetails?.fundSize}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.NON_NEGATIVE}
                        validationMessage={fundDetails?.fundSize === "" ? validationMessages.REQUIRED : validationMessages.NON_NEGATIVE}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.US_CURRENCY}
                        name="minimumInvestmentAmount"
                        label="Minimum Investment Amount"
                        defaultValue={fundDetails?.minimumInvestmentAmount}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.NON_NEGATIVE}
                        validationMessage={fundDetails?.minimumInvestmentAmount === "" ? validationMessages.REQUIRED : validationMessages.NON_NEGATIVE}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />
                </div>
                <div className="fundDetail">
                    <AppTextInput
                        type={InputTypes.SELECT}
                        name="fundStatus"
                        label="Fund Status"
                        defaultValue={fundDetails?.fundStatus}
                        options={props?.fundFilterData?.fundStatus?.map(option => ({ label: option.fundStatusName, value: option.fundStatusId }))}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AppTextInput
                        type={InputTypes.SELECT}
                        name="vintage"
                        label="Vintage Year"
                        defaultValue={fundDetails?.vintage}
                        options={json.Years.map(option => ({ label: option.name, value: option.name }))}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                        dataType="number" />
                </div>
                {/* <div className="fundDetail">
                    <AppTextInput
                        textFormatType={textFormatTypeEnum.PERCENTAGE}
                        name="targettedIRR"
                        label="Target Return"
                        defaultValue={fundDetails?.targettedIRR}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.PERCENTAGE}
                        validationMessage={validationMessages.PERCENTAGE}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid}
                    />

                </div> */}
                {
                    props.fundData.FundDetails?.fundTypeName === FundTypes.PRIVATE_CREDIT ?
                        <div className="fundDetail">
                            <AppTextInput
                                type={InputTypes.SELECT}
                                name="legalStructureID"
                                label="Legal Structure"
                                defaultValue={fundDetails?.legalStructureID}
                                options={props?.fundFilterData?.legalStructure.map(option => ({ label: option.legalStructureName, value: option.legalStructureID }))}
                                onChange={(name, value) => handleChange(name, value)}
                                validateField={true}
                                validationType={validationsTypeEnum.REQUIRED}
                                validationMessage={validationMessages.REQUIRED}
                                checkIfValid={checkIfValid}
                                checknoofValidFields={checknoofValidFields}
                                setcheckIfValid={setcheckIfValid} />
                        </div> :
                        <div className="fundDetail">
                            <AppTextInput
                                //textFormatType={textFormatTypeEnum.ONLY_NUMBERS}
                                name="investmentPeriodCriteria"
                                label="Term"
                                defaultValue={(fundDetails?.investmentPeriodCriteria)}
                                onChange={(name, value) => handleChange(name, value)}
                                validateField={false}
                                charactersMaxLength={2} />
                        </div>
                }
            </div>
            <div className="fundRow">
                <div className="fundDetail">
                    <AutoSelect
                        type={InputTypes.AUTOSELECT}
                        variant="standard"
                        name="InvestmentCycle"
                        label="Liquidity"
                        defaultValue={fundDetails?.InvestmentCycle}
                        // required={true}
                        error={error.liquidityProvisionId}
                        options={props?.fundFilterData?.liquidityProvision?.map(option => ({ label: option.liquidityProvisionName, value: option.liquidityProvisionId }))}
                        onChange={(name, value) => handleChange(name, value)}
                    //validateField={true}
                    //validationType={validationsTypeEnum.REQUIRED}
                    //validationMessage={validationMessages.REQUIRED}
                    //checkIfValid={checkIfValid}
                    //checknoofValidFields={checknoofValidFields}
                    //setcheckIfValid={setcheckIfValid} 
                    />
                </div>
                <div className="fundDetail">
                    <AppTextInput
                        type={InputTypes.SELECT}
                        textFormatType={'info'}
                        infoMessage={"Show: When this option is selected, the fund will be displayed in the marketplace,Hide: When this option is selected the fund will not be displayed in the marketplace"}
                        name="visibility"
                        label="Visibility"
                        defaultValue={fundDetails?.visibility}
                        options={json.Visibility.map(option => ({ label: option.name, value: option.value }))}
                        onChange={(name, value) => handleChange(name, value)}
                        validateField={true}
                        validationType={validationsTypeEnum.REQUIRED}
                        validationMessage={validationMessages.REQUIRED}
                        checkIfValid={checkIfValid}
                        checknoofValidFields={checknoofValidFields}
                        setcheckIfValid={setcheckIfValid} />
                </div>
            </div>
            <div className="fundRow">

                <>
                    {
                        props.fundData.FundDetails?.fundTypeName === FundTypes.PRIVATE_CREDIT ? <></> : <div className="fundDetail">
                            <AppTextInput
                                type={InputTypes.SELECT}
                                name="legalStructureID"
                                label="Legal Structure"
                                defaultValue={fundDetails?.legalStructureID}
                                options={props?.fundFilterData?.legalStructure.map(option => ({ label: option.legalStructureName, value: option.legalStructureID }))}
                                onChange={(name, value) => handleChange(name, value)}
                                validateField={true}
                                validationType={validationsTypeEnum.REQUIRED}
                                validationMessage={validationMessages.REQUIRED}
                                checkIfValid={checkIfValid}
                                checknoofValidFields={checknoofValidFields}
                                setcheckIfValid={setcheckIfValid} />
                        </div>
                    }
                </>
            </div>
        </div >
    )
}

export default memo(FundDetails);