import * as React from 'react';
import Badge from '@mui/material/Badge';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { notificationsLength } from '../../InvestorRelations/Services/GroupsServices';
import secureLocalStorage from 'react-secure-storage';
import { useEffect } from 'react';
import AccountMenu from '../CommonContextMenu/CommonContextMenu';
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { AppDashboards } from '../../../utils/enum';
import { getTopNavColorConfig } from '../../../state/slices/colorConfigSlice';
import { MarkAsRead } from '../../InvestorRelations/Services/GroupsServices';

export default function SimpleBadge({ }) {

  //#region useState Variables
  const [DraftNotifcations, setDraftNotifications] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const topNavColorConfig = useSelector(getTopNavColorConfig);
  const [menuItems, setMenuItems] = useState(null);
  const navigate = useNavigate();
  const [showNotificationLen, setshowNotificationLen] = useState(true)

  const handleMenuClick = (event) => {
    if (menuItems) {
      if (menuItems?.length === 0) {
        navigate("/all-notifications");
        handleClose();
      } else {
        setAnchorEl(event.currentTarget);
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const onViewMoreClick = useCallback(() => {
    secureLocalStorage.setItem('notificationsLength', true)
    navigate("/all-notifications");
    handleClose();
  }, [navigate]);


  //#to Post as Mark As read when User clicked on Notification and redirect to the respective page
  const handleNotificationClick = async (item) => {
    const { notifcationType, notificationDetailsID, notificationURL, notificationHasAction } = item;
    const response = await MarkAsRead(notificationDetailsID, secureLocalStorage.getItem("userId"));
    if (response?.responseCode === 200) {
      getDraft()
      const userRole = secureLocalStorage.getItem('userrole');
      if (userRole === AppDashboards.INV_DASHBOARD) {
        let selectedTabIndex = 0;
        if (notificationHasAction === true) {
          switch (notifcationType) {
            case "Personal information":
              selectedTabIndex = 0;
              navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
              break;
            case "Banking information":
              selectedTabIndex = 1;
              navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
              break;
            case "Investment profile":
              selectedTabIndex = 2;
              navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
              break;
            case "Subscription details":
              selectedTabIndex = 3;
              navigate('/user-profile', { state: { selectedTab: selectedTabIndex } });
              break;
            case "Marketplace":
              navigate('/marketplace');
              break;
            case "All funds from marketplace":
              secureLocalStorage.setItem("FundId", parseInt(notificationURL.match(/\d+$/)[0]));
              navigate('/fund-details', {
                state: {
                  FundID: parseInt(notificationURL.match(/\d+$/)[0]),
                  InvestorID: secureLocalStorage.getItem("userId"),
                  SubscriptionID: 0,
                  navigatefrommarketplace: true,
                  disableSubscriptionButton: false
                }
              });
              break;
            case "K1Reporting":
              secureLocalStorage.setItem("FundId", parseInt(notificationURL.match(/\d+$/)[0]));
              navigate(notificationURL);
              break;
            default:
              selectedTabIndex = 0;
              navigate('/all-notifications');
              break;
          }
        }
        else {
          navigate("/all-notifications");
        }
      }
      else if (userRole === AppDashboards.CO_DASHBOARD ||
        userRole === AppDashboards.FM_DASHBOARD) {
        switch (notifcationType) {
          case "Notify User on Investor Comments":
          case "Notify User on KYCAML Update":
            navigate(notificationURL);
            break;
        }
      } else {
        console.log("User does not have the required role for navigation.");
      }
    } else {
      console.log("Error in marking as read");
    }
  };

  //#region service GET Calls 
  const getDraft = async () => {
    const data = await notificationsLength(secureLocalStorage.getItem("userId"));
    if (data?.responseCode == 200) {
      setshowNotificationLen(false)
      setDraftNotifications(data?.responseData);
      const Items = data?.responseData
        ? data?.responseData
          .filter(item => !item.isRead)
          .map(item => ({
            label: item?.notificationSubject,
            relatedText: item?.notificationBody,
            notifcationType: item?.notificationType,
            notificationDetailsID: item?.notificationDetailsID,
            notificationURL: item?.notificationURL,
            notificationHasAction: item?.notificationHasAction
          }))
        : [];
      setMenuItems(Items);
      setshowNotificationLen(true)
    }
    else {
      setDraftNotifications(null)
      setMenuItems([]);
    }
  }

  //#region useEffect & Data Mapping
  useEffect(() => {
    getDraft();
  }, []);

  useEffect(() => {
    getDraft();
  }, [secureLocalStorage.getItem('notificationsLength')])

  return (
    <>{showNotificationLen && <div className='cursor-pointer'>
      <div onClick={handleMenuClick}>
        <Badge badgeContent={!menuItems ? 0 : menuItems?.length} color="primary" anchorOrigin={{
          vertical: 'middle',
          horizontal: 'right',
        }}  >
          <NotificationsNoneIcon color="action"
            style={{ width: 35, height: 35, color: topNavColorConfig.iconColor }} />
        </Badge>
      </div>
      <div className='newNotifyWindow'>
        <AccountMenu
          header="Notifications"
          menuItems={menuItems || []}
          footer="VIEW ALL"
          anchorEl={anchorEl}
          open={open}
          handleClose={handleClose}
          onMenuItemClick={handleNotificationClick}
          onViewMoreClick={onViewMoreClick}
        />
      </div>
    </div>}</>
  );
}
